/* variable
----------------------------------------------- */

$color-main: #2e3739;
$color-sub1: #d8af1c;
$color-sub2: #5b8d26;
$color-sub3: #1c74d8;
$color-sub4: #e34545;
$color-bg-gray1: #f7f7f7;
$color-bg-gray2: #e2e2e2;

/* inport
----------------------------------------------- */

@import "_mediaquery"; // メディアクエリ
@import "_family"; // Sassライブラリ

/* all
----------------------------------------------- */

html {
    -webkit-overflow-scrolling: touch;
}

body {
    font-family: "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
    font-weight: 500;
}

#mainContents {
    position: relative;
}

.h1-title {
    font-size: 32px;
    font-weight: 500;
    border-bottom: 1px solid #2e3739;
    margin: 0;
    padding: 0 0 15px;
    line-height: 1.2;
}
span.small {
    font-size: 80%;
}

/*****************************************************
*****************************************************
フレーム調整用
*****************************************************
*****************************************************/
html body {
    margin-top: 152px;
}
body .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
body .navBar {
    position: initial;
}
.header-campaign {
    a {
        display: block;
        background: #d8af1c;
        color: #FFF;
        padding: 12px 0;
        margin: 0;
        font-size: 12px;
        font-weight: bold;
    }
    span {
        font-size: 10px;
        text-decoration: underline;
        padding-left: 5px;
    }
}
.footer-calender {
    text-align: left;
    padding: 0 15px;
    margin: 30px 0 0;
    .footerInfoTitle {
        color: $color-main;
        font-weight: bold;
        font-size: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
        margin: 0 0 20px;
    }
    .txt-wrap {
        font-size: 11px;
    }
    .close {
        display: inline-block;
        width: 18px;
        height: 13px;
        margin-left: 10px;
        vertical-align: middle;
        background: #e34545;
    }
    .morning {
        display: inline-block;
        width: 18px;
        height: 13px;
        margin-left: 5px;
        vertical-align: middle;
        background: #d8af1c;
    }
    a.btn-shop-guide {
        display: block;
        color: $color-main;
        background: #fff;
        border: 1px solid $color-main;
        margin-top: 20px;
        padding: 6px 8px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        border-radius: 5px;
    }
}

.copyright {
    letter-spacing: 1px;
}

#sideMenu {
    padding-top: 180px;
    padding-left: 5%;
    padding-right: 5%;
}

.sideNav-login a {
    margin: 12px 0;
}

.riceTableWrap {
    margin: 50px 15px 5px;
    padding-bottom: 20px;
}

.scrollText {
    padding: 0 15px;
    font-size: 12px;
    color: #888;
    img {
        width: 19px;
        margin-right: 5px;
    }
}

.riceTable {
    width: 100%;
    table-layout: fixed;
    colgroup {
        col:first-child {
            width: 116px;
        }
        col {
            width: 135px;
        }
    }
    .row1 th {
        padding: 10px;
        text-align: center;
        // width: 116px;
        box-sizing: border-box;
        font-size: 12px;
        // &:first-child{
        //  width: 200px;
        // }
    }
    tr {
        th:first-child {
            font-size: 12px;
        }
    }
    th,
    td {
        padding: 4px 4px;
        border: 1px solid #ccc;
        vertical-align: middle;
    }
    th {
        background: #faf8df;
    }
    td {
        text-align: center;
        background: #fff;
    }
    .comment {
        margin-top: 3px;
        text-align: center;
        font-size: 10px;
        color: #2e3739;
    }
    .detailButton {
        display: block;
        padding: 4px;
        text-decoration: none;
        border: 1px solid #b7b7b7;
        border-radius: 8px;
        background: #f7f7f7;
        font-size: 12px;
        font-weight: bold;
        color: #2e3739;
        &:hover {
            opacity: 0.7;
        }
    }
    .riceIcon {
        display: block;
        width: 76px;
        height: 20px;
        margin: auto;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: 0 0, 25% 0, 50% 0, 75% 0, 100% 0;
        background-size: 13px;
    }
    .rice1 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice1-5 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice2 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice2-5 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice3 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice3-5 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice4 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    }
    .rice4-5 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png");
    }
    .rice5 {
        @extend .riceIcon;
        background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png");
    }
}

.riceGraph {
    margin-top: 30px;
}


/*****************************************************
スマホメニュー分の修正
*****************************************************/

#sideMenu {
    .heading2 {
        text-align: left;
    }
    .topButton {
        margin: 20px -5px 30px;
        a {}
    }
    .searchArea {
        padding: 5px;
        padding-right: 0;
        letter-spacing: -1rem;
        input[name="brand_search_form"] {
            width: 85%;
            letter-spacing: normal;
        }
        input[name="brand_search_button"] {
            width: 15%;
            height: 36px;
            background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images_sp/icon_serch.svg) no-repeat 50% #333;
            background-size: 18px 18px;
            padding: 0;
        }
    }
    .search-hint {
        margin: 6px -3px 0;
        text-align: left;
        a {
            display: block;
            padding: 5px;
            border: 1px solid #333;
            letter-spacing: normal;
        }
        i.fa {
            padding-right: 2px;
        }
    }
}

ul.box-list {
    letter-spacing: 0;
    .box-item {
        display: inline-block;
        margin: 3px;
    }
}

.sideCategory1 {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    text-align: left;
    a {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        border: 1px solid transparent;
        padding: 5px 8px;
        font-size: 13px;
        color: #333;
    }
    img {
        max-width: 60px;
        margin-right: 12px;
    }
}

.sideCategory2>ul>li,
.sideCategory3>ul>li {
    margin: 0 -8px;
    &>a {
        font-size: 13px;
        padding: 5px 8px;
        text-align: left;
    }
}

#mainContents {
    .content2 .infoList dl dt,
    .content2 .infoList dl dd {
        padding: 10px;
    }
    .content2 .infoList dl dt {
        width: 35%;
    }
    .content2 .infoList dl dd {
        width: 65%;
    }
}

#mainContents .banner .bannerItem,
#mainContents .banner a {
    display: block;
}

.footerMiddle {
    .instagram {
        margin-left: 6px;
        img {
            width: 46px;
        }
    }
}


/*****************************************************
TOPの修正
*****************************************************/

h2.textTitle {
    font-weight: 500;
}

.ranking {
    border-bottom: 1px solid #e5e5e5;
}

.search {
    padding-top: 60px;
    .category {
        padding: 0;
        border: none;
    }
    .categoryList {
        margin-top: 0;
    }
}

iframe#iframe-howto {
    width: 100vw !important;
}


/* キャッシュレスバナー */

.banner-cashless {
    padding: 20px 10px 10px;
}

.banner-cashless-footer {
    padding: 25px 15px 5px;
}

.search .links .link3 {
    margin-top: 15px;
}

.search .brandList {
    margin-top: 30px;
}


/* レビュー */
.top-review {
    padding: 60px 15px;
    border-bottom: 1px solid #e5e5e5;
    .inner-top-review {
        margin: 20px 0 40px;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 6px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-thumb {
            background: #d8af1c;
            border-radius: 6px;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
        }
    }
    .itemListReview {
        margin: 0;
        li {
            border-bottom: 1px dashed #ccc;
        }
        a {
            padding: 15px 0;
        }
        .itemDetail {
            padding-left: 10px;
        }
        .reviewBox {
            margin-top: 10px;
        }
    }
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto
}
@media screen and(max-width: 767px) {
    .table-responsive {
        width: 100%;
        height: 420px;
        margin-bottom: 15px;
        overflow-y: auto;
    }
    .table-responsive>.table {
        margin-bottom: 0
    }
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap
    }
}
.table-responsive .riceTable {
    th,
    td {
        padding: 3px 8px;
    }
    th:not([class]),
    td:not([class]) {
        width: 124px;
    }
    .row1 th {
        padding: 3px 3px;
        font-size: 12px;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 2;
        span {
            display: block;
            font-size: 10px;
        }
    }
    .row1 th.name {
        text-align: center;
    }
    th.name {
        position: sticky;
        left: 0;
        width: 116px;
        text-align: left;
    }
    .img {
        padding: 3px;
        width: 60px;
        img {
            width: 40px;
        }
    }
    .blend {
        width: 74px;
        padding: 0;
    }
    .comment {
        width: 170px;
        text-align: left;
    }
}





/*****************************************************
/*  商品一覧
*****************************************************/

#list_item {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 7px;
    padding-right: 7px;
}

#list_item li {
    width: 100%;
    margin: 0 0 15px;
    flex: 0 1 50%;
    padding: 0 7px;
    box-sizing: border-box;
    img {
        width: 100%;
        height: auto;
    }
    .itemDetail .price {
        margin-top: 6px;
        em {
            font-size: 15px;
        }
    }
    &:nth-child(3n) {
        margin-right: 0;
    }
}

#mainContents .searchForm {
    margin-bottom: 0;
}

#search-detail-wrap {
    margin-top: -100px;
    padding-top: 100px;
}

.fixed-link {
    position: fixed;
    display: block;
    background: #eee;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 85px;
    z-index: 10;
}

#all-itemlist-only {
    display: none;
}

#price-select-wrap {
    padding: 30px 10px 25px;
    background: #f7f7f7;
    .r_subGroup_title {
        margin-bottom: 13px;
    }
    .price-select-ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        li {
            padding: 5px;
            flex-basis: calc(100% / 4);
            box-sizing: border-box;
        }
        a {
            display: block;
            text-align: center;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;
            background: #fff;
            border: 1px solid #ddd;
            color: #333;
            text-decoration: none;
            &:hover {
                color: #fff;
                background: $color-main;
                border-color: $color-main;
            }
        }
    }
}

.bnr-search-wrap {
    margin: 20px auto;
    .popular {
        margin: 10px auto;
        max-width: 310px;
        a {
            display: block;
        }
    }
}

.r_subGroup_title {
    display: flex;
    align-items: center;
    margin: 0 auto 18px;
    padding: 0;
    font-size: 13px;
    font-weight: bold;
    &::before,
    &::after {
        content: "";
        flex-grow: 1;
        border-top: 1px solid #2e3739;
    }
    &::before {
        margin-right: 1rem;
    }
    &::after {
        margin-left: 1rem;
    }
}

.category-jitaku {
    margin: 0 0 45px;
    .toggle-title {
        position: relative;
        padding: 10px 20px;
        text-align: center;
        background: #5b8d26;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            display: inline-block;
            width: 15px;
            height: 2px;
            margin: auto;
            background: #fff;
        }
        &:after {
            content: "";
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            display: inline-block;
            width: 15px;
            height: 2px;
            margin: auto;
            background: #fff;
            transform: rotate(90deg);
        }
        .hide {
            display: none;
        }
        &.active {
            .hide {
                display: inline-block;
            }
            .show {
                display: none;
            }
            &:after {
                content: "";
                display: none;
            }
        }
    }
    .toggle-content {
        padding: 20px 10px;
        background: #f7f7f7;
    }
    .riceTableWrap {
        margin: 0;
        padding-bottom: 10px;
    }
    .riceTable {
        table-layout: fixed;
        margin: 0;
    }
    .scrollText {
        margin-top: 10px;
        padding: 0 15px;
        font-size: 12px;
        color: #888;
        img {
            width: 19px;
            margin-right: 5px;
        }
    }
    .riceGraph {
        img {
            width: 100%;
        }
    }
}

// 子カテゴリを常に表示
#catNarrowdown h3 {
    a {
        pointer-events: none;
    }
    span {
        background: none !important;
        padding: 0 !important;
    }
}

#catNarrowdown .catBox {
    display: block !important;
}

#catNarrowdown #child_category_list {
    display: flex !important;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    li {
        flex-basis: calc(100% / 3 - 10px);
        padding: 5px;
        a {
            box-sizing: border-box;
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
            background: #f7f7f7;
            border: 1px solid #95989a;
            color: #2e3739;
        }
    }
}

#sideMenu .scene-list {
    a {
        font-size: 13px;
    }
}

/*****************************************************
/*  商品詳細
*****************************************************/

.detailPage .itemSection.itemImg {
    min-height: 355px;
}

.detailPage .itemInfo {
    .itemDetail {
        margin: 10px 0 30px;
        font-size: 15px;
        p {
            margin: 1rem 0;
        }
    }
    table {
        width: 100%;
        font-size: 95%;
        margin: 30px 0;
    }
    table caption {
        margin: 0;
        text-align: left;
        letter-spacing: 1px;
        color: #fff;
        background: $color-main;
        padding: 6px;
    }
    // tr:first-child{
    //   background: #2E3739;
    // }
    td,
    th {
        padding: 6px 8px;
        border: 1px solid #d8d8d8;
    }
    td input {
        margin: 3px 0;
    }
    th {
        background: rgba(0, 0, 0, 0.05);
        text-align: left;
    }
}

.detailPage {
    a.subscription-btn {
        display: block;
        margin: 30px 0;
        background: #5b8d26;
        color: #FFF;
        text-decoration: none;
        padding: 15px;
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
        width: 100%;
    }
    .btn.other-size {
        width: 100%;
        display: block;
        color: #2e3739;
        background: #f7f7f7;
        border: 1px solid #ccc;
        padding: 12px 20px;
        margin: 15px 0;
        border-radius: 6px;
        font-size: 13px;
        font-weight: bold;
        text-align: left;
        text-decoration: none;
        position: relative;
        &:hover {
            background-color: #2e3739;
            border-color: #2e3739;
            color: #fff;
        }
        i {
            position: absolute;
            right: 20px;
            top: 50%;
            margin: auto;
            line-height: 0;
        }
    }
    #other-size-list {
        padding: 100px 10px 10px;
        margin-top: -80px;
    }
    p.consume_price {
        padding: 6px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        margin: 10px 0 -10px !important;
        color: #e22828;
        display: inline-block;
    }
    #M_usualValue {
        display: block;
    }
    .review-option-info {
        padding: 16px;
        margin: 20px 0;
        border: 1px solid #5b8d26;
        background: #f0f6ea;
        border-radius: 10px;
        text-align: left;
        .title {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 12px;
            color: #5b8d26;
            text-align: left;
        }
        .content {
            font-size: 13px;
            color: #5b8d26;
            margin: 10px 0;
            text-align: left;
        }
        a {
            display: inline-block;
            color: #5b8d26;
            font-size: 13px;
        }
    }
}

.fukuimaiList {
    p {
        font-size: 13px;
    }
}

.ingredientText {
    font-size: 18px;
}

.reason-wrapper,
.fukuimai {
    .fukidasi {
        font-size: 21px;
        position: relative;
        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            height: auto;
        }
        &::before {
            left: 15px;
        }
        &::after {
            right: 15px;
        }
    }
    .riceGraph {
        margin-bottom: 50px;
    }
}

.reason-pickup-wrap {
    margin: 40px 12px 0;
    .text-center {
        text-align: center;
    }
    .reason-label {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        background: #b77c17;
        font-size: 13px;
        color: #fff;
        margin-bottom: 20px;
    }
    .reason-pickup {
        text-align: left;
        display: inline-block;
    }
    .reason-pickup li {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.4;
        margin: 8px 0;
        a {
            color: #333;
            text-decoration: none;
            &:hover {
                opacity: 0.8;
            }
        }
        span.icon-num {
            display: inline-block;
            margin-right: 8px;
            border-radius: 100px;
            background: #b77c17;
            color: #fff;
            width: 22px;
            height: 22px;
            line-height: 23px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.bnr-wrap {
    background: #eee;
    padding: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    .recommend-title {
        font-size: 15px;
        font-weight: bold;
        margin: 0 -10px 10px;
    }
}

.detailPage .itemSection.itemBtn {
    border-bottom: none;
}


// fixed-cart
.fixed-cart {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
    background: rgba(255, 255, 255, 0.95);
    border-top: 1px solid #ccc;
    padding: 10px 15px;
    .flex-wrap {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .price-wrap {
        flex-basis: auto;
        font-size: 12px;
        line-height: 1;
        letter-spacing: .05em;
        text-align: left;
        padding-right: 10px;
    }
    #fixed-price {
        font-size: 20px;
        font-weight: bold;
        color: #E34545;
        padding: 0;
        padding-right: 0 3px;
    }
    .btn-wrap {
        flex-basis: 40%;
        flex-grow: 1;
        flex-shrink: 1;
    }
    .btn-cart {
        display: block;
        background: #E34545;
        color: #fff;
        margin: 0;
        padding: 10px 10px;
        font-size: 13px;
        font-weight: bold;
    }
}
#anchor-cart {
    padding-top: 70px;
    margin-top: -70px;
}

.review-p {
    text-align: center;
    margin: 30px auto 0;
    font-size: 13px;
}


/*****************************************************
/*  当店について
*****************************************************/

.mainview {
    span {
        display: block;
    }
}


/*****************************************************
/*  選ばれる理由
*****************************************************/

.content1 {
    .fastSection,
    .inexpensiveSection,
    .safeSection {
        &>.text {
            text-align: left;
            margin: 15px;
            padding: 0;
            font-size: 15px;
        }
    }
    .imageList li .imageListTitle {
        text-align: center;
        .icon {
            margin: 0;
        }
        .listTitle {
            line-height: 1.5;
        }
    }
    a.btn {
        background: $color-sub2;
        color: #fff;
        border-radius: 5px;
        margin-top: 30px;
    }
}


/*****************************************************
/*  当店について
*****************************************************/

.content2 {
    a.btn {
        background: $color-sub2;
        color: #fff;
        border-radius: 5px;
        margin-top: 30px;
    }
    .greetingSection {
        display: block;
        .greetingText {
            padding: 20px 0 0;
        }
    }
}

.content2 .contributionSection .contributionList li {
    padding: 30px 15px;
    .title {
        margin: 0 0 15px;
    }
    span.data {
        display: block;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 6px;
        letter-spacing: 2px;
    }
    .imageWrap {
        margin: 15px 0;
        p {
            margin: 0;
        }
    }
    .text {
        text-align: left;
        padding: 0;
    }
}


/*****************************************************
/*  福井県のお米が美味しい理由
*****************************************************/

.content3 .reasonsSection {
    padding-bottom: 30px;
}

.content3 .reasonsList li {
    .title {
        line-height: 1.4;
    }
    p.text {
        text-align: left;
        font-size: 15px;
    }
    a.btn {
        background: $color-sub2;
        color: #fff;
        border-radius: 5px;
        margin-top: 30px;
    }
}


/*****************************************************
/*  大口注文の方へ
*****************************************************/

.content4 {
    .textTitle {
        font-size: 28px;
        font-weight: bold;
    }
    .productSection {
        .title {
            font-size: 28px;
            font-weight: bold;
        }
        .typeList {
            li {
                padding: 5px;
                .text {
                    margin: 0;
                    font-size: 15px;
                    text-align: left;
                }
                .image {
                    margin: 0;
                }
            }
            .image {
                margin-bottom: 20px;
            }
        }
        .polish .text2 {
            text-align: left;
        }
    }
    .compareSection .compareInner {
        background: rgba(255, 255, 255, 0.85);
        .text3,
        .text4,
        .text5 {
            font-size: 13px;
            text-align: left;
            padding: 0;
        }
    }
    .voiceSection .voiceList {
        .textWrap {
            text-align: center;
        }
        .name,
        .text {
            text-align: left;
            padding: 0;
        }
        .name a {
            font-size: 14px;
            font-family: "arial";
        }
    }
    .voiceSection .heading {
        margin: 50px auto 20px;
    }
    .voiceSection .formButton {
        margin-top: 0;
    }
    a.btn {
        background: $color-sub2;
        color: #fff;
        border-radius: 5px;
        margin-top: 30px;
    }
    .voiceSection .formButton a {
        background: $color-sub4;
        width: 100%;
    }
}


/*****************************************************
/*  福井精米の取り組み
*****************************************************/

#page-safety,
#page-shopping-guide {
    margin-bottom: 30px;
    .main-contents {
        text-align: left;
    }
    .breadcrumb {
        margin: 20px 10px;
        text-align: left;
        font-size: 13px;
        color: #333;
    }
    .safety-contents {
        margin-top: 60px;
        p {
            font-size: 15px;
            line-height: 1.8;
        }
    }
    .bd-content {
        margin-bottom: 0;
    }
    .process-type li {
        text-align: center;
    }
    a.btn {
        background: $color-sub2;
        color: #fff;
        border-radius: 5px;
        margin-top: 30px;
    }
}


/*****************************************************
/*  買い物ガイド
*****************************************************/

#page-shopping-guide {
    .archive-title {
        margin: 15px;
    }
    .anchor-wrap {
        margin: 10px 0 0;
        text-align: left;
    }
}


/*****************************************************
/*  ランキング
*****************************************************/

#page-ranking {
    .main-contents {
        text-align: left;
        padding-top: 15px;
    }
    .breadcrumb {
        margin: 20px 10px;
        text-align: left;
        font-size: 13px;
        color: #333;
    }
    .flex-wrap.ranking .flex-item {
        flex-basis: calc(50% - 10px);
        &:first-child {
            flex-basis: 100%;
        }
        img {
            width: 100% !important;
        }
    }
}


/*****************************************************
/*  買い物かご
*****************************************************/

#cartin-page {
    padding: 40px 0;
    .h1-title {
        border: none;
        padding-bottom: 0;
        margin-bottom: 30px;
    }
    .heading.cartHeading {
        margin-bottom: 20px;
    }
    .totalPrice {
        color: $color-sub4;
    }
    .btn.btnSubmit {
        background: $color-sub4;
        color: #fff;
    }
    .btn.btnShopping {
        display: block;
        margin: 15px auto;
        padding: 3px;
        color: $color-main;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .cartList {
        li {
            margin: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #ddd;
            text-align: left;
        }
        .btnDelete {
            display: inline-block;
            width: auto;
            background: #666;
            color: #fff;
            font-size: 12px;
            letter-spacing: 2px;
            padding: 3px 10px;
        }
    }
    .cartItem {
        // padding: 15px 15px 5px;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        .cartImg {
            flex: 0 1 30%;
            padding-right: 5%;
        }
        dd {
            flex: 1 1 65%;
            text-align: left;
            p {
                margin: 8px 0;
            }
        }
        .itemName {
            font-weight: bold;
            margin-top: 0;
        }
        .unitPrice {
            font-size: 11px;
            color: #666;
            letter-spacing: 0.05rem;
        }
        .basketPrice {
            font-weight: bold;
            color: $color-sub4;
        }
        .amount {
            display: flex;
            justify-content: flex-start;
            input {
                padding: 6px 10px;
                flex-basis: 50%;
            }
            .btnAmount {
                padding: 6px 10px;
                margin-left: 8px;
                font-size: 14px;
                color: #fff;
                background: $color-sub3;
                text-align: center;
                flex-basis: 30%;
            }
        }
    }
    .cartTotal {
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .totalTxt {
        display: inline-block;
    }
    .totalPrice {
        display: inline-block;
        font-weight: bold;
    }
}


/* 商品詳細
----------------------------------------------- */

.detailPage img {
    max-width: 100%;
    height: auto;
}

.detailPage {
    margin-bottom: 15px;
    .itemInfo {
        margin-bottom: 30px;
    }
    .itemOption select {
        margin-bottom: 0;
    }
    .itemOption {
        padding: 0 0 20px;
    }
    .itemAmount {
        padding: 0;
    }
    .normal-cart,
    .repeat-sale-cart {
        .itemSection {
            border: none;
            padding: 10px 0;
        }
    }
    .normal-cart {
        padding: 0 15px 30px;
    }
    .repeat-sale-cart {
        padding: 25px 15px 10px;
        input[name="repeat_sale_amount"] {
            width: 6em;
        }
    }

    .basket-p {
        font-size: 12px;
        margin: 20px 0 0;
        text-align: left;
        p {
            margin-top: 12px;
        }
        .btn-wrap {
            display: none;
        }
    }

    .cart-type-title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        margin-bottom: 15px;
        span {
            display: inline-block;
            background: #fff;
            padding-right: 10px;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 10px;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 1px;
            background: #333;
            z-index: -1;
        }
    }
    .contactLink {
        padding: 12px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin-top: -1px;
    }
}

.itemOption,
.itemAmount {
    padding: 8px 10px;
    font-weight: bold;
    font-size: 15px;
}

.itemDetail table {
    tr {
        @include first(1) {
            td[colspan="2"] {
                background: $color-main !important;
                color: #fff !important;
            }
        }
    }
    td {
        @include first(1) {
            td[width="30%"] {
                background: $color-bg-gray1 !important;
            }
        }
    }
}


/* 特商法に関する
----------------------------------------------- */

.M_ordercontract {
    text-align: left;
    margin: 30px 15px;
    .M_ordercontractTitle {
        background: $color-main;
        color: #fff;
        padding: 12px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    dl {
        margin: 20px 0;
    }
    dt {
        font-weight: bold;
    }
}


/* お米選びフローチャート
----------------------------------------------- */

#iframe-chart {
    margin: 40px auto;
    height: 800px !important;
    background: #eee;
    padding-top: 30px;
}

.bnr-rice-chart {
    padding: 15px 15px 30px;
}


/* 農家直送カテゴリ（一覧）
----------------------------------------------- */

.category-farmer {
    margin-top: -10px;
    p {
        text-align: left;
        font-size: 14px;
        line-height: 2;
    }
    .caption-wrap {
        background: #f5f1e3;
        padding: 30px 15px;
    }
    .title-wrap {
        .title {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.3;
            color: #2e3739;
        }
        .en {
            font-size: 16px;
            color: #5b8d26;
            font-family: "arial", sans-serif;
            margin: 18px 0 30px;
            letter-spacing: 0.2rem;
            text-align: center;
        }
    }
    .category-content {
        padding: 30px 15px;
        p {
            margin-bottom: 30px;
        }
    }
}


/* 農家直送カテゴリ（詳細）
----------------------------------------------- */

#farmer-category-detail {
    margin-top: 20px;
    .mt-sm {
        margin-top: 26px;
    }
    .mt-lg {
        margin-top: 46px;
    }
}

.inner-width {
    width: 100%;
    max-width: 900px;
    img {
        max-width: 100%;
    }
}

.item-spac .txt-link {
    margin-left: 6px;
    font-size: 13px;
    text-decoration: underline;
    color: #1049a0;
    &:hover {
        text-decoration: none;
        color: lighten(#1049a0, 20%);
    }
}

// .pc-col2 {
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: flex-start;
//   align-items: flex-start;
//   margin: 0 -15px;
//   .pc-col2-item {
//     flex-basis: 50%;
//     padding: 0 15px;
//     box-sizing: border-box;
//   }
//   &.image {
//     .pc-col2-item {
//       @include odd() {
//         flex-basis: 395px;
//       }
//       @include even() {
//         flex-basis: calc(100% - 395px);
//       }
//     }
//   }
// }
.template {
    margin-bottom: 60px;
    .title-wrap {
        margin-bottom: 20px;
        text-align: center;
        .title {
            display: inline-block;
            color: #5b8d26;
            font-size: 26px;
            font-weight: bold;
            padding: 0 10px 5px;
            border-bottom: 1px solid #5b8d26;
        }
        .title-line {
            display: inline-block;
            text-align: center;
            color: #b77c17;
            font-size: 21px;
            font-weight: bold;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            span {
                padding: 0 15px;
            }
            &::before,
            &::after {
                content: "";
                display: block;
                flex-basis: auto;
                flex-grow: 1;
                flex-shrink: 1;
                height: 1px;
                background: #b77c17;
            }
        }
    }
    .text {
        font-size: 14px;
        line-height: 1.8;
        text-align: left;
        .caption {
            font-size: 21px;
            font-weight: bold;
            line-height: 1.3;
            margin-bottom: 15px;
        }
    }
}

.farmer-contents {
    background: #fefaf4;
    padding: 60px 20px 1px;
    margin: 0 -10px -10px;
    .title-wrap {
        margin-bottom: 20px;
        line-height: 1;
        text-align: center;
        .farmer-title {
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            display: inline-block;
            position: relative;
            color: #b77c17;
            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: 2px;
                height: 60px;
                vertical-align: middle;
                background: #b77c17;
                position: absolute;
                bottom: 0;
            }
            &::before {
                left: 0;
                margin-left: -30px;
                transform: rotate(-18deg);
            }
            &::after {
                right: 0;
                margin-right: -30px;
                transform: rotate(18deg);
            }
            .sub {
                font-size: 20px;
                display: block;
                margin-bottom: 10px;
            }
        }
    }
    .text {
        padding: 0;
    }
}

.farmer-info-wrap {
    margin-bottom: 60px;
    align-items: center;
    p {
        font-size: 14px;
    }
    // .img-wrap {
    //   flex-basis: 330px;
    //   img {
    //     border-radius: 10px;
    //   }
    // }
    // .txt-wrap {
    //   flex-basis: calc(100% - 330px);
    // }
    .farmer-outline {
        // position: relative;
        margin-top: 20px;
    }
    .address {
        font-size: 13px;
        margin-bottom: 5px;
    }
    .name {
        font-size: 21px;
        font-weight: bold;
    }
    .icon-wrap {
        // position: absolute;
        // top: 0;
        // right: 0;
        margin: 10px 0 20px;
        .icon {
            display: inline-block;
            border: 1px solid #ddd;
            background: #fff;
            font-size: 11px;
            font-weight: bold;
            margin: 1px;
            padding: 4px 8px;
        }
    }
    .farmer-txt {
        line-height: 1.8;
        margin: 15px 0;
    }
    .farmer-msg {
        border: 1px solid #eee;
        border-radius: 10px;
        background: #fff;
        padding: 15px;
        position: relative;
        &::before {
            content: "お客様へののメッセージ";
            display: block;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        // &::before,
        // &::after {
        //   content: "";
        //   position: absolute;
        //   display: block;
        //   width: 0;
        //   height: 0;
        //   top: 0;
        //   bottom: 0;
        //   margin: auto;
        //   border-style: solid;
        //   border-width: 10px 20px 10px 20px;
        // }
        // &::before {
        //   left: -39px;
        //   border-color: transparent #FFF transparent transparent;
        //   z-index: 2;
        // }
        // &::after {
        //   left: -40px;
        //   border-color: transparent #DDD transparent transparent;
        //   z-index: 1;
        // }
    }
}

.award-history {
    padding: 15px;
    border: 2px solid #eee;
    background: #fff;
    margin: -30px 0 60px;
    &::after {
        content: "";
        display: block;
        clear: both;
    }
    img {
        // max-width: 170px;
        // margin-right: 20px;
        // float: left;
        margin-bottom: 15px;
    }
    dl {
        text-align: left;
        dt {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        dd {
            font-size: 12px;
            margin: 8px 0;
        }
    }
}

table.farmer-spac {
    width: 100%;
    font-size: 95%;
    margin: 30px 0;
    & * {
        display: block;
    }
    caption {
        margin: 0;
        text-align: left;
        letter-spacing: 1px;
        color: #fff;
        background: $color-main;
        padding: 6px 10px;
    }
    td,
    th {
        padding: 6px 8px;
        border: 1px solid #d8d8d8;
        margin-top: -1px;
    }
    td:first-child[colspan="2"] {
        background: $color-main;
    }
    td input {
        margin: 3px 0;
    }
    th {
        background: #f7f7f7;
        text-align: left;
    }
    td {
        background: #fff;
    }
}


/* 重要なお知らせ */

dl.important-news {
    margin: 30px 15px;
    border: 2px solid #e61f1f;
    padding: 20px 15px 20px 20px;
    text-align: left;
    color: #e61f1f;
    dt {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    dd {
        font-size: 13px;
        line-height: 1.8;
    }
}


/* 定期購入カテゴリ（一覧）
----------------------------------------------- */

#repeat-sale-category {
    text-align: left;
    font-size: 16px;
    line-height: 1.8;
    .main-image {
        margin: 0 0 30px;
    }
    .caption-wrap {
        padding: 0 15px;
        .img {
            margin: 30px 0;
        }
    }
    .caption-title {
        font-size: 21px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .point-wrap {
        margin: 50px 0 0;
        padding: 0 15px;
        .r_subGroup_title {
            border: none;
            padding: 0;
            background: none;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.3;
            text-align: center;
        }
    }
    .point-inner-wrap {
        margin-bottom: 50px;
        .title-wrap {
            border: 1px solid #333;
            border-left: none;
            border-right: none;
            padding: 18px 0;
            margin: 0 0 30px;
            font-weight: bold;
            text-align: left;
            .label {
                display: inline-block;
                color: #fff;
                font-size: 13px;
                background: #e22828;
                padding: 3px 12px;
                margin-right: 10px;
                border-radius: 3px;
            }
            .num {
                font-size: 16px;
            }
            .title {
                font-size: 21px;
                display: block;
                margin: 5px 0 0;
                line-height: 1.4;
                text-align: left;
            }
        }
        .img-wrap {}
        .txt-wrap {
            p {
                margin: 0 0 20px;
            }
            dl {
                margin: 0 0 20px;
                padding: 0;
            }
            dt {
                font-weight: bold;
                margin: 0;
                padding: 0;
                span {
                    font-size: 14px;
                }
            }
            dd {
                margin: 0 0 15px;
                padding: 12px 0 15px;
                border-bottom: 1px solid #ddd;
                &.last {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: none;
                }
            }
        }
        ul.caution {
            padding-left: 15px;
            li {
                text-align: left;
                list-style: disc;
                margin: 10px 0;
                font-size: 13px;
                line-height: 1.8;
            }
            .btn {
                display: inline-block;
                background: #2e3739;
                color: #fff;
                text-decoration: none;
                font-size: 13px;
                line-height: 1;
                padding: 10px 20px;
                border-radius: 100px;
                margin-top: 10px;
                margin-right: 5px;
                width: auto;
            }
            .btn-line {
                background-color: #21CE26;
            }
        }
    }
    .guide-wrap {
        border-radius: 10px;
        background: #f7f7f7;
        padding: 40px 20px;
        text-align: center;
        margin-bottom: 50px;
        .guide-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            margin: 0 0 20px;
            text-align: center;
        }
        .btn-wrap {
            text-align: center;
            .btn {
                display: inline-block;
                padding: 12px 25px;
                border-radius: 100px;
                background: #5b8d26;
                color: #fff;
                line-height: 1.4;
                font-size: 16px;
                text-decoration: none;
                width: auto;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        .tel-wrap {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 1px solid #ccc;
            .guide-title {
                font-weight: bold;
                margin-bottom: 25px;
                color: #E34545;
            }
            .contact-btn {
                margin-top: 15px;
                .btn {
                    display: inline-block;
                    background: #2e3739;
                    color: #fff;
                    text-decoration: none;
                    font-size: 13px;
                    line-height: 1;
                    padding: 10px 20px;
                    border-radius: 100px;
                    margin-top: 10px;
                    margin-right: 5px;
                    width: auto;
                }
                .btn-line {
                    background-color: #21CE26;
                }
            }
        }
    }
}


/* ご利用ガイド（定期購入を追加）
----------------------------------------------- */

#page-shopping-guide {
    #repeat-sale-info {
        strong {
            font-size: 100%;
            display: inline;
            &::after {
                content: none;
            }
        }
        .flex-wrap {
            display: block;
            padding: 15px;
        }
    }
    img {
        max-width: 100%;
    }
    .point-title {
        font-size: 21px;
        font-weight: bold;
        color: #e22828;
        margin-top: 40px;
        margin-bottom: 20px;
        padding: 0;
        border: none;
    }
    ul {
        li {
            margin: 8px 0;
        }
        .main {
            font-size: 18px;
            font-weight: bold;
            margin: 20px 0 0;
        }
        .sub {
            margin: 0 0 20px;
        }
    }
    a.btn {
        text-align: center;
        line-height: 1.2;
        display: inline-block;
        color: #fff;
        background: #5b8d26;
        text-decoration: none;
        padding: 12px 20px;
        border-radius: 100px;
        transition: all 0.2s ease;
        margin-top: 15px;
        width: 100%;
        &:hover {
            background-color: #e22828;
            transition: all 0.2s ease;
        }
    }
    .step-wrap {
        padding: 0;
        margin-bottom: 30px;
        dl {
            padding: 0;
        }
        .step-label {
            background: #d8af1c;
            color: #fff;
            line-height: 38px;
            height: 38px;
            padding: 0 5px 0 12px;
            position: relative;
            font-size: 16px;
            font-weight: 500;
            margin-right: 22px;
            display: inline-block;
            &::after {
                position: absolute;
                top: 0;
                right: -15px;
                content: "";
                border-style: solid;
                border-width: 19px 0 19px 15px;
                border-color: transparent transparent transparent #d8af1c;
            }
            &+.txt {
                display: block;
                margin-top: 15px;
            }
        }
        .txt-wrap {
            // flex-basis: 425px;
        }
        .img-wrap {
            // flex-basis: 300px;
        }
        p {
            margin: 20px 0;
        }
        dt {
            font-size: 18px;
        }
        .note {
            padding-left: 25px;
            position: relative;
            color: #666;
            &::before {
                content: "＊";
                position: absolute;
                top: 0px;
                left: 2px;
            }
        }
    }
    .colorbox-img {
        padding: 0 15px;
    }
    .colorbox-img p {
        margin-top: 5px;
    }
    .access-wrap {
        margin-top: 30px;
        .caption {
            margin-top: 0;
            margin-bottom: 10px;
            font-weight: bold;
        }
        .img-wrap {
            max-width: 300px;
            p {
                margin-bottom: 0;
            }
        }
    }
}


/* 重要なお知らせ枠
----------------------------------------------- */

.important-news {
    display: block;
    border: 3px solid #c7000b;
    color: #c7000b !important;
    text-decoration: none !important;
    margin: 15px 15px 10px;
    .important-news-title {
        display: block;
        margin: 0;
        padding: 20px 20px 10px;
        color: #c7000b !important;
        position: relative;
        text-decoration: none;
        text-align: left;
    }
    .collapse-toggle {
        &:hover,
        &:active {
            color: #c7000b !important;
            text-decoration: none !important;
        }
        &::after {
            position: absolute;
            display: inline-block;
            content: "\f107";
            margin: auto;
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            right: 15px;
            top: 20px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .collapse-toggle.open {
        &::after {
            content: "\f106";
        }
    }
    .collapse-inner {
        display: none;
        text-align: left;
        font-size: 13px;
    }
    dl {
        margin: 0;
        padding: 20px;
    }
    .dt,
    .dd {
        display: inline-block;
        margin: 0 0 10px;
        vertical-align: middle;
    }
    .dt {
        font-size: 16px;
        font-weight: bold;
        text-decoration: underline;
        margin-right: 20px;
    }
    .dd {
        font-size: 13px;
    }
    .important-news-inner {
        .inner-wrap {
            padding: 20px 20px 10px;
            background: rgba(199, 0, 11, 0.05);
        }
        p {
            margin-bottom: 10px;
            line-height: 1.8;
        }
        a {
            font-weight: bold;
            display: inline-block;
        }
    }
    &.takarakuji {
        display: block;
        margin: -5px 15px 10px;
        .important-news-title {
            &:hover,
            &:active {
                color: #c7000b !important;
                text-decoration: none !important;
            }
            &::after {
                content: "";
                position: absolute;
                display: inline-block;
                content: "\f105";
                margin: auto;
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                right: 15px;
                top: 20px;
            }
        }
    }
}

.fc-red {
    color: #ff0000;
}

.fw-b {
    font-weight: bold;
}



/* カテゴリ おすすめコンテンツ
----------------------------------------------- */
.for-recommend-wrap {
    margin: 30px 0;
    padding: 0;
    dt {
        padding: 10px 15px;
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 21px;
        line-height: 1.3;
        background: #5D9027;
        color: #FFF;
        text-shadow: 3px 3px 1px #3c5d18;
    }
    dd {
        margin: 0;
        padding: 20px;
        background: #FFFEEC;
        text-align: left;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        margin: 0 0 15px;
        padding: 0;
        padding-left: 35px;
        list-style: none;
        position: relative;
        font-size: 15px;
        font-weight: 500;
        @include last(1) {
            margin-bottom: 0;
        }
        &::before {
            content: '';
            display: block;
            background: url(../images/category/icon-check.svg) no-repeat 50%;
            background-size: 100%;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 3px;
        }
    }
}


/* ミルキークイーン カテゴリ
----------------------------------------------- */
.bold {
    font-weight: bold;
}
.red {
    color: #CC0000;
}
#milky-queen-category {
    padding: 0 15px;
    img {
        max-width: 100%;
    }
    .main-image {
        border: none;
        background: none;
        padding: 0;
        margin: 0 -15px 30px;
    }
    .caption-wrap {
        text-align: left;
    }
    p {
        font-size: 16px;
        line-height: 1.8;
    }
    .section {
        padding: 0;
        margin: 0 0 80px;
    }
    .bd-title {
        border: none;
        border-radius: 0;
        background: none;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        padding: 12px 0;
        margin-bottom: 30px;
        line-height: 1.3;
        .num-label {
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 20px;
        }
    }
    .balloon {
        text-align: center;
        margin: 0 auto 30px;
        padding: 0 30px;
        background: none;
        border: none;
        color: #b77c17;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.3;
        position: relative;
        display: inline-block;
        .small {
            display: block;
            font-size: 21px;
        }
        &:before,
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 8px;
            width: 0;
            height: 60%;
            border-right: 1px solid #b77c17;
        }
        &:before {
            transform: rotate(-15deg);
            margin-right: 35px;
            left: 0;
        }
        &:after {
            transform: rotate(15deg);
            margin-left: 35px;
            right: 0;
        }
        &.mini {
            font-size: 14px;
            line-height: 1.4;
            padding: 0 20px;
            &:before,
            &:after {
                bottom: 0;
            }
        }
    }
    .caption-wrap {
        margin-bottom: 30px;
    }
    .anchor-toc {
        display: inline-block;
        text-align: left;
        margin-bottom: 30px;
        li {
            margin: 10px 0;
        }
        a {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            color: #222;
            font-size: 14px;
            font-weight: bold;
        }
        .num-label {
            border-radius: 100px;
            padding: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
        }
        .num-label span {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .num-label {
        padding: 6px 12px;
        margin-right: 8px;
        border-radius: 5px;
        background: #D8AF1C;
        color: #FFF;
        font-size: 11px;
        line-height: 1.2;
        span {
            font-size: 16px;
        }
    }

    // 人気の理由 -----
    .section.reason {
        border: none;
    }
    .reason-wrap {
        margin-bottom: 50px;
        .bd-title {
            font-size: 18px;
            text-align: center;
            .num-label {
                display: inline-block;
                margin: 0 auto 10px;
            }
        }
    }
    .mini-knowledge {
        margin: 20px auto;
        img {
            display: block;
            width: 112px;
            margin: 0 auto 20px;
        }
        p {
            font-size: 14px;
            text-align: left;
        }
    }
    .reason-answer {
        background: #FFFEEC;
        padding: 20px;
        p {
            font-size: 14px;
            text-align: left;
        }
    }
    .img-wrap {
        margin-top: 10px;
        img {
            width: 100%;
        }
    }


    // お客様の声 -----
    .voice-title {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        .txt {
            order: 2;
            text-align: left;
            padding-left: 20px;
            .small {
                display: block;
                font-size: 15px;
                font-weight: 500;
            }
            .big {
                font-size: 32px;
                font-weight: bold;
            }
        }
        img {
            order: 1;
            width: 104px;
        }
        & + p {
            background: #F5F5F5;
            padding: 5px;
            border-radius: 5px;
            text-align: center;
            font-size: 12px;
        }
    }
    .voice-img {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -5px;
        padding: 20px 0 0;
        li {
            flex-basis: 50%;
            padding: 10px 5px;
            box-sizing: border-box;
        }
    }
    .modal-voice {
        // .modal-inner {
        //     max-width: 800px;
        // }
        .txt {
            margin: 30px 0 0;
            padding: 20px;
            border-radius: 10px;
            background: #F5F5F5;
            p {
                font-size: 14px;
                text-align: left;
            }
        }
        .title {
            font-weight: bold;
            border-bottom: 1px solid #000;
            margin: 0 0 10px;
            padding-bottom: 5px;
            font-size: 16px;
        }
    }

    // ここにも注目 -----
    .attention-list {
        margin: 0;
        padding: 0;
        li {
            margin: 10px 0;
            border-radius: 10px;
        }
        li.txt {
            padding: 20px;
            background: #F5F5F5;
            text-align: left;
            .title {
                font-size: 21px;
                font-weight: 500;
                text-align: left;
                color: #CC0000;
                margin: 0 0 15px;
            }
            p {
                font-size: 14px;
            }
        }
    }

    // サブカテゴリ導線 -----
    .sub-category-list {
        li {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            img {
                display: block;
                width: 157px;
                flex-basis: 157px;
                flex-grow: 1;
                flex-shrink: 1;
            }
            .txt {
                flex-basis: auto;
                flex-grow: 1;
                flex-shrink: 1;
                text-align: left;
                padding-left: 12px;
            }
        }
        .title {
            color: #222;
            font-size: 15px;
            font-weight: bold;
            line-height: 1.3;
            margin: 5px 0 10px;
            text-align: left;
        }
        .small {
            font-size: 13px;
            font-weight: bold;
            line-height: 1.5;
            color: #CC0000;
        }
        .btn {
            display: inline-block;
            padding: 5px 12px;
            color: #5B8D26;
            border: 1px solid #5B8D26;
            border-radius: 100px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }
    }

    // 美味しい炊き方 -----
    .how-to-cook {
        text-align: left;
        .bold {
            margin-top: 20px;
        }
        table {
            width: 100%;
            margin: 15px 0;
            th,td {
                text-align: center;
                border: 1px solid #ccc;
                font-size: 16px;
                padding: 8px;
                vertical-align: middle;
            }
            th {
                background: #FFFEEC;
                font-weight: bold;
            }
        }
        .note {
            font-size: 13px;
        }
    }

    // 基礎知識 -----
    .knowledge {
        .bd-title {
            background: url(../images/category/bg-knowledge-title@2x.png) 50% 70% no-repeat;
            background-size: 100%;
            padding: 20px 0;
            font-size: 24px;
            .small {
                display: block;
                margin-bottom: 5px;
                font-size: 15px;
            }
        }
    }
    .knowledge-scroll-wrap {
        background: #F5F5F5;
        padding: 20px;
        border-radius: 10px;
        text-align: left;
        .inner {
            max-height: 300px;
            overflow-y: scroll;
            padding-right: 10px;
            /* スクロールの幅の設定 */
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            /* スクロールの背景の設定 */
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: #ddd;
            }
            /* スクロールのつまみ部分の設定 */
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: #2D3739;
            }
        }
        h3 {
            font-size: 16px;
            border-bottom: 1px solid #999;
            padding-bottom: 8px;
            margin: 35px auto 18px;
            @include first(1) {
                margin-top: 0;
            }
        }
        p {
            margin: 0 0 13px;
            font-size: 13px;
        }
    }

}
.fixed-anchor {
    position: fixed;
    bottom: 17px;
    left: -5px;
    width: 147px;
    z-index: 20;
}

// modal-style -----------
.modal-open:hover {
    cursor: pointer;
    opacity: 0.8;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    .modal-contents {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-inner {
        background: #fff;
        padding: 30px;
        position: relative;
        z-index: 101;
        box-sizing: border-box;
        .modal-off {
            position: absolute;
            top: 0;
            right: 0;
            background: #eee;
            border: none;
            font-size: 10px;
            padding: 0.5rem;
            border-radius: 0;
            line-height: 1;
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0;
            text-align: center;
            &:hover {
                background: #ddd;
                cursor: pointer;
            }
        }
    }
    .modalBK {
        position: fixed;
        background: rgba(0,0,0,.7);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 100;
    }
}

// end / modal-style -----------

/*****************************************************
商品一覧ページ（独自ページ）を追加
*****************************************************/
#item-list-wrapper {
    .inner-wrap {
        padding: 15px;
    }
    .currentName {
        margin: 30px 5px 0;
    }
    .searchArea {
        padding: 5px;
        padding-right: 0;
        letter-spacing: -1rem;
        input[name="brand_search_form"] {
            width: 85%;
            letter-spacing: normal;
        }
        input[name="brand_search_button"] {
            width: 15%;
            height: 36px;
            background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images_sp/icon_serch.svg) no-repeat 50% #333;
            background-size: 18px 18px;
            padding: 0;
        }
    }
    .search-hint {
        margin: 6px -3px 0;
        text-align: left;
        a {
            display: block;
            padding: 5px;
            border: 1px solid #333;
            letter-spacing: normal;
        }
        i.fa {
            padding-right: 2px;
        }
    }
    .sideCategory1,
    .sideCategory2,
    .sideCategory3 {
        border-bottom: none;
    }
}