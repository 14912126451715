@charset "UTF-8";
/* variable
----------------------------------------------- */
/* inport
----------------------------------------------- */
/* all
----------------------------------------------- */
html {
  -webkit-overflow-scrolling: touch; }

body {
  font-family: "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  font-weight: 500; }

#mainContents {
  position: relative; }

.h1-title {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 1px solid #2e3739;
  margin: 0;
  padding: 0 0 15px;
  line-height: 1.2; }

span.small {
  font-size: 80%; }

/*****************************************************
*****************************************************
フレーム調整用
*****************************************************
*****************************************************/
html body {
  margin-top: 152px; }

body .fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; }

body .navBar {
  position: initial; }

.header-campaign a {
  display: block;
  background: #d8af1c;
  color: #FFF;
  padding: 12px 0;
  margin: 0;
  font-size: 12px;
  font-weight: bold; }

.header-campaign span {
  font-size: 10px;
  text-decoration: underline;
  padding-left: 5px; }

.footer-calender {
  text-align: left;
  padding: 0 15px;
  margin: 30px 0 0; }
  .footer-calender .footerInfoTitle {
    color: #2e3739;
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px; }
  .footer-calender .txt-wrap {
    font-size: 11px; }
  .footer-calender .close {
    display: inline-block;
    width: 18px;
    height: 13px;
    margin-left: 10px;
    vertical-align: middle;
    background: #e34545; }
  .footer-calender .morning {
    display: inline-block;
    width: 18px;
    height: 13px;
    margin-left: 5px;
    vertical-align: middle;
    background: #d8af1c; }
  .footer-calender a.btn-shop-guide {
    display: block;
    color: #2e3739;
    background: #fff;
    border: 1px solid #2e3739;
    margin-top: 20px;
    padding: 6px 8px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    border-radius: 5px; }

.copyright {
  letter-spacing: 1px; }

#sideMenu {
  padding-top: 180px;
  padding-left: 5%;
  padding-right: 5%; }

.sideNav-login a {
  margin: 12px 0; }

.riceTableWrap {
  margin: 50px 15px 5px;
  padding-bottom: 20px; }

.scrollText {
  padding: 0 15px;
  font-size: 12px;
  color: #888; }
  .scrollText img {
    width: 19px;
    margin-right: 5px; }

.riceTable {
  width: 100%;
  table-layout: fixed; }
  .riceTable colgroup col:first-child {
    width: 116px; }
  .riceTable colgroup col {
    width: 135px; }
  .riceTable .row1 th {
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px; }
  .riceTable tr th:first-child {
    font-size: 12px; }
  .riceTable th,
  .riceTable td {
    padding: 4px 4px;
    border: 1px solid #ccc;
    vertical-align: middle; }
  .riceTable th {
    background: #faf8df; }
  .riceTable td {
    text-align: center;
    background: #fff; }
  .riceTable .comment {
    margin-top: 3px;
    text-align: center;
    font-size: 10px;
    color: #2e3739; }
  .riceTable .detailButton {
    display: block;
    padding: 4px;
    text-decoration: none;
    border: 1px solid #b7b7b7;
    border-radius: 8px;
    background: #f7f7f7;
    font-size: 12px;
    font-weight: bold;
    color: #2e3739; }
    .riceTable .detailButton:hover {
      opacity: 0.7; }
  .riceTable .riceIcon, .riceTable .rice1, .riceTable .rice1-5, .riceTable .rice2, .riceTable .rice2-5, .riceTable .rice3, .riceTable .rice3-5, .riceTable .rice4, .riceTable .rice4-5, .riceTable .rice5 {
    display: block;
    width: 76px;
    height: 20px;
    margin: auto;
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 0 0, 25% 0, 50% 0, 75% 0, 100% 0;
    background-size: 13px; }
  .riceTable .rice1 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice1-5 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice2 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice2-5 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice3 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice3-5 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice4 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
  .riceTable .rice4-5 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"); }
  .riceTable .rice5 {
    background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"); }

.riceGraph {
  margin-top: 30px; }

/*****************************************************
スマホメニュー分の修正
*****************************************************/
#sideMenu .heading2 {
  text-align: left; }

#sideMenu .topButton {
  margin: 20px -5px 30px; }

#sideMenu .searchArea {
  padding: 5px;
  padding-right: 0;
  letter-spacing: -1rem; }
  #sideMenu .searchArea input[name="brand_search_form"] {
    width: 85%;
    letter-spacing: normal; }
  #sideMenu .searchArea input[name="brand_search_button"] {
    width: 15%;
    height: 36px;
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images_sp/icon_serch.svg) no-repeat 50% #333;
    background-size: 18px 18px;
    padding: 0; }

#sideMenu .search-hint {
  margin: 6px -3px 0;
  text-align: left; }
  #sideMenu .search-hint a {
    display: block;
    padding: 5px;
    border: 1px solid #333;
    letter-spacing: normal; }
  #sideMenu .search-hint i.fa {
    padding-right: 2px; }

ul.box-list {
  letter-spacing: 0; }
  ul.box-list .box-item {
    display: inline-block;
    margin: 3px; }

.sideCategory1 {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
  text-align: left; }
  .sideCategory1 a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 5px 8px;
    font-size: 13px;
    color: #333; }
  .sideCategory1 img {
    max-width: 60px;
    margin-right: 12px; }

.sideCategory2 > ul > li,
.sideCategory3 > ul > li {
  margin: 0 -8px; }
  .sideCategory2 > ul > li > a,
  .sideCategory3 > ul > li > a {
    font-size: 13px;
    padding: 5px 8px;
    text-align: left; }

#mainContents .content2 .infoList dl dt,
#mainContents .content2 .infoList dl dd {
  padding: 10px; }

#mainContents .content2 .infoList dl dt {
  width: 35%; }

#mainContents .content2 .infoList dl dd {
  width: 65%; }

#mainContents .banner .bannerItem,
#mainContents .banner a {
  display: block; }

.footerMiddle .instagram {
  margin-left: 6px; }
  .footerMiddle .instagram img {
    width: 46px; }

/*****************************************************
TOPの修正
*****************************************************/
h2.textTitle {
  font-weight: 500; }

.ranking {
  border-bottom: 1px solid #e5e5e5; }

.search {
  padding-top: 60px; }
  .search .category {
    padding: 0;
    border: none; }
  .search .categoryList {
    margin-top: 0; }

iframe#iframe-howto {
  width: 100vw !important; }

/* キャッシュレスバナー */
.banner-cashless {
  padding: 20px 10px 10px; }

.banner-cashless-footer {
  padding: 25px 15px 5px; }

.search .links .link3 {
  margin-top: 15px; }

.search .brandList {
  margin-top: 30px; }

/* レビュー */
.top-review {
  padding: 60px 15px;
  border-bottom: 1px solid #e5e5e5; }
  .top-review .inner-top-review {
    margin: 20px 0 40px; }
    .top-review .inner-top-review::-webkit-scrollbar {
      width: 6px; }
    .top-review .inner-top-review::-webkit-scrollbar-track {
      border-radius: 6px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); }
    .top-review .inner-top-review::-webkit-scrollbar-thumb {
      background: #d8af1c;
      border-radius: 6px;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3); }
  .top-review .itemListReview {
    margin: 0; }
    .top-review .itemListReview li {
      border-bottom: 1px dashed #ccc; }
    .top-review .itemListReview a {
      padding: 15px 0; }
    .top-review .itemListReview .itemDetail {
      padding-left: 10px; }
    .top-review .itemListReview .reviewBox {
      margin-top: 10px; }

.table-responsive {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    height: 420px;
    margin-bottom: 15px;
    overflow-y: auto; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: nowrap; } }

.table-responsive .riceTable th,
.table-responsive .riceTable td {
  padding: 3px 8px; }

.table-responsive .riceTable th:not([class]),
.table-responsive .riceTable td:not([class]) {
  width: 124px; }

.table-responsive .riceTable .row1 th {
  padding: 3px 3px;
  font-size: 12px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2; }
  .table-responsive .riceTable .row1 th span {
    display: block;
    font-size: 10px; }

.table-responsive .riceTable .row1 th.name {
  text-align: center; }

.table-responsive .riceTable th.name {
  position: sticky;
  left: 0;
  width: 116px;
  text-align: left; }

.table-responsive .riceTable .img {
  padding: 3px;
  width: 60px; }
  .table-responsive .riceTable .img img {
    width: 40px; }

.table-responsive .riceTable .blend {
  width: 74px;
  padding: 0; }

.table-responsive .riceTable .comment {
  width: 170px;
  text-align: left; }

/*****************************************************
/*  商品一覧
*****************************************************/
#list_item {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 7px;
  padding-right: 7px; }

#list_item li {
  width: 100%;
  margin: 0 0 15px;
  flex: 0 1 50%;
  padding: 0 7px;
  box-sizing: border-box; }
  #list_item li img {
    width: 100%;
    height: auto; }
  #list_item li .itemDetail .price {
    margin-top: 6px; }
    #list_item li .itemDetail .price em {
      font-size: 15px; }
  #list_item li:nth-child(3n) {
    margin-right: 0; }

#mainContents .searchForm {
  margin-bottom: 0; }

#search-detail-wrap {
  margin-top: -100px;
  padding-top: 100px; }

.fixed-link {
  position: fixed;
  display: block;
  background: #eee;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 85px;
  z-index: 10; }

#all-itemlist-only {
  display: none; }

#price-select-wrap {
  padding: 30px 10px 25px;
  background: #f7f7f7; }
  #price-select-wrap .r_subGroup_title {
    margin-bottom: 13px; }
  #price-select-wrap .price-select-ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: -5px;
    margin-right: -5px; }
    #price-select-wrap .price-select-ul li {
      padding: 5px;
      flex-basis: calc(100% / 4);
      box-sizing: border-box; }
    #price-select-wrap .price-select-ul a {
      display: block;
      text-align: center;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      background: #fff;
      border: 1px solid #ddd;
      color: #333;
      text-decoration: none; }
      #price-select-wrap .price-select-ul a:hover {
        color: #fff;
        background: #2e3739;
        border-color: #2e3739; }

.bnr-search-wrap {
  margin: 20px auto; }
  .bnr-search-wrap .popular {
    margin: 10px auto;
    max-width: 310px; }
    .bnr-search-wrap .popular a {
      display: block; }

.r_subGroup_title {
  display: flex;
  align-items: center;
  margin: 0 auto 18px;
  padding: 0;
  font-size: 13px;
  font-weight: bold; }
  .r_subGroup_title::before, .r_subGroup_title::after {
    content: "";
    flex-grow: 1;
    border-top: 1px solid #2e3739; }
  .r_subGroup_title::before {
    margin-right: 1rem; }
  .r_subGroup_title::after {
    margin-left: 1rem; }

.category-jitaku {
  margin: 0 0 45px; }
  .category-jitaku .toggle-title {
    position: relative;
    padding: 10px 20px;
    text-align: center;
    background: #5b8d26;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer; }
    .category-jitaku .toggle-title:before {
      content: "";
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      display: inline-block;
      width: 15px;
      height: 2px;
      margin: auto;
      background: #fff; }
    .category-jitaku .toggle-title:after {
      content: "";
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      display: inline-block;
      width: 15px;
      height: 2px;
      margin: auto;
      background: #fff;
      transform: rotate(90deg); }
    .category-jitaku .toggle-title .hide {
      display: none; }
    .category-jitaku .toggle-title.active .hide {
      display: inline-block; }
    .category-jitaku .toggle-title.active .show {
      display: none; }
    .category-jitaku .toggle-title.active:after {
      content: "";
      display: none; }
  .category-jitaku .toggle-content {
    padding: 20px 10px;
    background: #f7f7f7; }
  .category-jitaku .riceTableWrap {
    margin: 0;
    padding-bottom: 10px; }
  .category-jitaku .riceTable {
    table-layout: fixed;
    margin: 0; }
  .category-jitaku .scrollText {
    margin-top: 10px;
    padding: 0 15px;
    font-size: 12px;
    color: #888; }
    .category-jitaku .scrollText img {
      width: 19px;
      margin-right: 5px; }
  .category-jitaku .riceGraph img {
    width: 100%; }

#catNarrowdown h3 a {
  pointer-events: none; }

#catNarrowdown h3 span {
  background: none !important;
  padding: 0 !important; }

#catNarrowdown .catBox {
  display: block !important; }

#catNarrowdown #child_category_list {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px; }
  #catNarrowdown #child_category_list li {
    flex-basis: calc(100% / 3 - 10px);
    padding: 5px; }
    #catNarrowdown #child_category_list li a {
      box-sizing: border-box;
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      background: #f7f7f7;
      border: 1px solid #95989a;
      color: #2e3739; }

#sideMenu .scene-list a {
  font-size: 13px; }

/*****************************************************
/*  商品詳細
*****************************************************/
.detailPage .itemSection.itemImg {
  min-height: 355px; }

.detailPage .itemInfo .itemDetail {
  margin: 10px 0 30px;
  font-size: 15px; }
  .detailPage .itemInfo .itemDetail p {
    margin: 1rem 0; }

.detailPage .itemInfo table {
  width: 100%;
  font-size: 95%;
  margin: 30px 0; }

.detailPage .itemInfo table caption {
  margin: 0;
  text-align: left;
  letter-spacing: 1px;
  color: #fff;
  background: #2e3739;
  padding: 6px; }

.detailPage .itemInfo td,
.detailPage .itemInfo th {
  padding: 6px 8px;
  border: 1px solid #d8d8d8; }

.detailPage .itemInfo td input {
  margin: 3px 0; }

.detailPage .itemInfo th {
  background: rgba(0, 0, 0, 0.05);
  text-align: left; }

.detailPage a.subscription-btn {
  display: block;
  margin: 30px 0;
  background: #5b8d26;
  color: #FFF;
  text-decoration: none;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%; }

.detailPage .btn.other-size {
  width: 100%;
  display: block;
  color: #2e3739;
  background: #f7f7f7;
  border: 1px solid #ccc;
  padding: 12px 20px;
  margin: 15px 0;
  border-radius: 6px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  text-decoration: none;
  position: relative; }
  .detailPage .btn.other-size:hover {
    background-color: #2e3739;
    border-color: #2e3739;
    color: #fff; }
  .detailPage .btn.other-size i {
    position: absolute;
    right: 20px;
    top: 50%;
    margin: auto;
    line-height: 0; }

.detailPage #other-size-list {
  padding: 100px 10px 10px;
  margin-top: -80px; }

.detailPage p.consume_price {
  padding: 6px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 10px 0 -10px !important;
  color: #e22828;
  display: inline-block; }

.detailPage #M_usualValue {
  display: block; }

.detailPage .review-option-info {
  padding: 16px;
  margin: 20px 0;
  border: 1px solid #5b8d26;
  background: #f0f6ea;
  border-radius: 10px;
  text-align: left; }
  .detailPage .review-option-info .title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 12px;
    color: #5b8d26;
    text-align: left; }
  .detailPage .review-option-info .content {
    font-size: 13px;
    color: #5b8d26;
    margin: 10px 0;
    text-align: left; }
  .detailPage .review-option-info a {
    display: inline-block;
    color: #5b8d26;
    font-size: 13px; }

.fukuimaiList p {
  font-size: 13px; }

.ingredientText {
  font-size: 18px; }

.reason-wrapper .fukidasi,
.fukuimai .fukidasi {
  font-size: 21px;
  position: relative; }
  .reason-wrapper .fukidasi::before, .reason-wrapper .fukidasi::after,
  .fukuimai .fukidasi::before,
  .fukuimai .fukidasi::after {
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto; }
  .reason-wrapper .fukidasi::before,
  .fukuimai .fukidasi::before {
    left: 15px; }
  .reason-wrapper .fukidasi::after,
  .fukuimai .fukidasi::after {
    right: 15px; }

.reason-wrapper .riceGraph,
.fukuimai .riceGraph {
  margin-bottom: 50px; }

.reason-pickup-wrap {
  margin: 40px 12px 0; }
  .reason-pickup-wrap .text-center {
    text-align: center; }
  .reason-pickup-wrap .reason-label {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    background: #b77c17;
    font-size: 13px;
    color: #fff;
    margin-bottom: 20px; }
  .reason-pickup-wrap .reason-pickup {
    text-align: left;
    display: inline-block; }
  .reason-pickup-wrap .reason-pickup li {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    margin: 8px 0; }
    .reason-pickup-wrap .reason-pickup li a {
      color: #333;
      text-decoration: none; }
      .reason-pickup-wrap .reason-pickup li a:hover {
        opacity: 0.8; }
    .reason-pickup-wrap .reason-pickup li span.icon-num {
      display: inline-block;
      margin-right: 8px;
      border-radius: 100px;
      background: #b77c17;
      color: #fff;
      width: 22px;
      height: 22px;
      line-height: 23px;
      text-align: center;
      font-size: 12px;
      font-weight: bold; }

.bnr-wrap {
  background: #eee;
  padding: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd; }
  .bnr-wrap .recommend-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 -10px 10px; }

.detailPage .itemSection.itemBtn {
  border-bottom: none; }

.fixed-cart {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  background: rgba(255, 255, 255, 0.95);
  border-top: 1px solid #ccc;
  padding: 10px 15px; }
  .fixed-cart .flex-wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between; }
  .fixed-cart .price-wrap {
    flex-basis: auto;
    font-size: 12px;
    line-height: 1;
    letter-spacing: .05em;
    text-align: left;
    padding-right: 10px; }
  .fixed-cart #fixed-price {
    font-size: 20px;
    font-weight: bold;
    color: #E34545;
    padding: 0;
    padding-right: 0 3px; }
  .fixed-cart .btn-wrap {
    flex-basis: 40%;
    flex-grow: 1;
    flex-shrink: 1; }
  .fixed-cart .btn-cart {
    display: block;
    background: #E34545;
    color: #fff;
    margin: 0;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: bold; }

#anchor-cart {
  padding-top: 70px;
  margin-top: -70px; }

.review-p {
  text-align: center;
  margin: 30px auto 0;
  font-size: 13px; }

/*****************************************************
/*  当店について
*****************************************************/
.mainview span {
  display: block; }

/*****************************************************
/*  選ばれる理由
*****************************************************/
.content1 .fastSection > .text,
.content1 .inexpensiveSection > .text,
.content1 .safeSection > .text {
  text-align: left;
  margin: 15px;
  padding: 0;
  font-size: 15px; }

.content1 .imageList li .imageListTitle {
  text-align: center; }
  .content1 .imageList li .imageListTitle .icon {
    margin: 0; }
  .content1 .imageList li .imageListTitle .listTitle {
    line-height: 1.5; }

.content1 a.btn {
  background: #5b8d26;
  color: #fff;
  border-radius: 5px;
  margin-top: 30px; }

/*****************************************************
/*  当店について
*****************************************************/
.content2 a.btn {
  background: #5b8d26;
  color: #fff;
  border-radius: 5px;
  margin-top: 30px; }

.content2 .greetingSection {
  display: block; }
  .content2 .greetingSection .greetingText {
    padding: 20px 0 0; }

.content2 .contributionSection .contributionList li {
  padding: 30px 15px; }
  .content2 .contributionSection .contributionList li .title {
    margin: 0 0 15px; }
  .content2 .contributionSection .contributionList li span.data {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 2px; }
  .content2 .contributionSection .contributionList li .imageWrap {
    margin: 15px 0; }
    .content2 .contributionSection .contributionList li .imageWrap p {
      margin: 0; }
  .content2 .contributionSection .contributionList li .text {
    text-align: left;
    padding: 0; }

/*****************************************************
/*  福井県のお米が美味しい理由
*****************************************************/
.content3 .reasonsSection {
  padding-bottom: 30px; }

.content3 .reasonsList li .title {
  line-height: 1.4; }

.content3 .reasonsList li p.text {
  text-align: left;
  font-size: 15px; }

.content3 .reasonsList li a.btn {
  background: #5b8d26;
  color: #fff;
  border-radius: 5px;
  margin-top: 30px; }

/*****************************************************
/*  大口注文の方へ
*****************************************************/
.content4 .textTitle {
  font-size: 28px;
  font-weight: bold; }

.content4 .productSection .title {
  font-size: 28px;
  font-weight: bold; }

.content4 .productSection .typeList li {
  padding: 5px; }
  .content4 .productSection .typeList li .text {
    margin: 0;
    font-size: 15px;
    text-align: left; }
  .content4 .productSection .typeList li .image {
    margin: 0; }

.content4 .productSection .typeList .image {
  margin-bottom: 20px; }

.content4 .productSection .polish .text2 {
  text-align: left; }

.content4 .compareSection .compareInner {
  background: rgba(255, 255, 255, 0.85); }
  .content4 .compareSection .compareInner .text3,
  .content4 .compareSection .compareInner .text4,
  .content4 .compareSection .compareInner .text5 {
    font-size: 13px;
    text-align: left;
    padding: 0; }

.content4 .voiceSection .voiceList .textWrap {
  text-align: center; }

.content4 .voiceSection .voiceList .name,
.content4 .voiceSection .voiceList .text {
  text-align: left;
  padding: 0; }

.content4 .voiceSection .voiceList .name a {
  font-size: 14px;
  font-family: "arial"; }

.content4 .voiceSection .heading {
  margin: 50px auto 20px; }

.content4 .voiceSection .formButton {
  margin-top: 0; }

.content4 a.btn {
  background: #5b8d26;
  color: #fff;
  border-radius: 5px;
  margin-top: 30px; }

.content4 .voiceSection .formButton a {
  background: #e34545;
  width: 100%; }

/*****************************************************
/*  福井精米の取り組み
*****************************************************/
#page-safety,
#page-shopping-guide {
  margin-bottom: 30px; }
  #page-safety .main-contents,
  #page-shopping-guide .main-contents {
    text-align: left; }
  #page-safety .breadcrumb,
  #page-shopping-guide .breadcrumb {
    margin: 20px 10px;
    text-align: left;
    font-size: 13px;
    color: #333; }
  #page-safety .safety-contents,
  #page-shopping-guide .safety-contents {
    margin-top: 60px; }
    #page-safety .safety-contents p,
    #page-shopping-guide .safety-contents p {
      font-size: 15px;
      line-height: 1.8; }
  #page-safety .bd-content,
  #page-shopping-guide .bd-content {
    margin-bottom: 0; }
  #page-safety .process-type li,
  #page-shopping-guide .process-type li {
    text-align: center; }
  #page-safety a.btn,
  #page-shopping-guide a.btn {
    background: #5b8d26;
    color: #fff;
    border-radius: 5px;
    margin-top: 30px; }

/*****************************************************
/*  買い物ガイド
*****************************************************/
#page-shopping-guide .archive-title {
  margin: 15px; }

#page-shopping-guide .anchor-wrap {
  margin: 10px 0 0;
  text-align: left; }

/*****************************************************
/*  ランキング
*****************************************************/
#page-ranking .main-contents {
  text-align: left;
  padding-top: 15px; }

#page-ranking .breadcrumb {
  margin: 20px 10px;
  text-align: left;
  font-size: 13px;
  color: #333; }

#page-ranking .flex-wrap.ranking .flex-item {
  flex-basis: calc(50% - 10px); }
  #page-ranking .flex-wrap.ranking .flex-item:first-child {
    flex-basis: 100%; }
  #page-ranking .flex-wrap.ranking .flex-item img {
    width: 100% !important; }

/*****************************************************
/*  買い物かご
*****************************************************/
#cartin-page {
  padding: 40px 0; }
  #cartin-page .h1-title {
    border: none;
    padding-bottom: 0;
    margin-bottom: 30px; }
  #cartin-page .heading.cartHeading {
    margin-bottom: 20px; }
  #cartin-page .totalPrice {
    color: #e34545; }
  #cartin-page .btn.btnSubmit {
    background: #e34545;
    color: #fff; }
  #cartin-page .btn.btnShopping {
    display: block;
    margin: 15px auto;
    padding: 3px;
    color: #2e3739; }
    #cartin-page .btn.btnShopping:hover {
      text-decoration: underline;
      cursor: pointer; }
  #cartin-page .cartList li {
    margin: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    text-align: left; }
  #cartin-page .cartList .btnDelete {
    display: inline-block;
    width: auto;
    background: #666;
    color: #fff;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 3px 10px; }
  #cartin-page .cartItem {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start; }
    #cartin-page .cartItem .cartImg {
      flex: 0 1 30%;
      padding-right: 5%; }
    #cartin-page .cartItem dd {
      flex: 1 1 65%;
      text-align: left; }
      #cartin-page .cartItem dd p {
        margin: 8px 0; }
    #cartin-page .cartItem .itemName {
      font-weight: bold;
      margin-top: 0; }
    #cartin-page .cartItem .unitPrice {
      font-size: 11px;
      color: #666;
      letter-spacing: 0.05rem; }
    #cartin-page .cartItem .basketPrice {
      font-weight: bold;
      color: #e34545; }
    #cartin-page .cartItem .amount {
      display: flex;
      justify-content: flex-start; }
      #cartin-page .cartItem .amount input {
        padding: 6px 10px;
        flex-basis: 50%; }
      #cartin-page .cartItem .amount .btnAmount {
        padding: 6px 10px;
        margin-left: 8px;
        font-size: 14px;
        color: #fff;
        background: #1c74d8;
        text-align: center;
        flex-basis: 30%; }
  #cartin-page .cartTotal {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px; }
  #cartin-page .totalTxt {
    display: inline-block; }
  #cartin-page .totalPrice {
    display: inline-block;
    font-weight: bold; }

/* 商品詳細
----------------------------------------------- */
.detailPage img {
  max-width: 100%;
  height: auto; }

.detailPage {
  margin-bottom: 15px; }
  .detailPage .itemInfo {
    margin-bottom: 30px; }
  .detailPage .itemOption select {
    margin-bottom: 0; }
  .detailPage .itemOption {
    padding: 0 0 20px; }
  .detailPage .itemAmount {
    padding: 0; }
  .detailPage .normal-cart .itemSection,
  .detailPage .repeat-sale-cart .itemSection {
    border: none;
    padding: 10px 0; }
  .detailPage .normal-cart {
    padding: 0 15px 30px; }
  .detailPage .repeat-sale-cart {
    padding: 25px 15px 10px; }
    .detailPage .repeat-sale-cart input[name="repeat_sale_amount"] {
      width: 6em; }
  .detailPage .basket-p {
    font-size: 12px;
    margin: 20px 0 0;
    text-align: left; }
    .detailPage .basket-p p {
      margin-top: 12px; }
    .detailPage .basket-p .btn-wrap {
      display: none; }
  .detailPage .cart-type-title {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin-bottom: 15px; }
    .detailPage .cart-type-title span {
      display: inline-block;
      background: #fff;
      padding-right: 10px; }
    .detailPage .cart-type-title::after {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1px;
      background: #333;
      z-index: -1; }
  .detailPage .contactLink {
    padding: 12px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-top: -1px; }

.itemOption,
.itemAmount {
  padding: 8px 10px;
  font-weight: bold;
  font-size: 15px; }

.itemDetail table tr:first-child td[colspan="2"] {
  background: #2e3739 !important;
  color: #fff !important; }

.itemDetail table td:first-child td[width="30%"] {
  background: #f7f7f7 !important; }

/* 特商法に関する
----------------------------------------------- */
.M_ordercontract {
  text-align: left;
  margin: 30px 15px; }
  .M_ordercontract .M_ordercontractTitle {
    background: #2e3739;
    color: #fff;
    padding: 12px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold; }
  .M_ordercontract dl {
    margin: 20px 0; }
  .M_ordercontract dt {
    font-weight: bold; }

/* お米選びフローチャート
----------------------------------------------- */
#iframe-chart {
  margin: 40px auto;
  height: 800px !important;
  background: #eee;
  padding-top: 30px; }

.bnr-rice-chart {
  padding: 15px 15px 30px; }

/* 農家直送カテゴリ（一覧）
----------------------------------------------- */
.category-farmer {
  margin-top: -10px; }
  .category-farmer p {
    text-align: left;
    font-size: 14px;
    line-height: 2; }
  .category-farmer .caption-wrap {
    background: #f5f1e3;
    padding: 30px 15px; }
  .category-farmer .title-wrap .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;
    color: #2e3739; }
  .category-farmer .title-wrap .en {
    font-size: 16px;
    color: #5b8d26;
    font-family: "arial", sans-serif;
    margin: 18px 0 30px;
    letter-spacing: 0.2rem;
    text-align: center; }
  .category-farmer .category-content {
    padding: 30px 15px; }
    .category-farmer .category-content p {
      margin-bottom: 30px; }

/* 農家直送カテゴリ（詳細）
----------------------------------------------- */
#farmer-category-detail {
  margin-top: 20px; }
  #farmer-category-detail .mt-sm {
    margin-top: 26px; }
  #farmer-category-detail .mt-lg {
    margin-top: 46px; }

.inner-width {
  width: 100%;
  max-width: 900px; }
  .inner-width img {
    max-width: 100%; }

.item-spac .txt-link {
  margin-left: 6px;
  font-size: 13px;
  text-decoration: underline;
  color: #1049a0; }
  .item-spac .txt-link:hover {
    text-decoration: none;
    color: #2c77ea; }

.template {
  margin-bottom: 60px; }
  .template .title-wrap {
    margin-bottom: 20px;
    text-align: center; }
    .template .title-wrap .title {
      display: inline-block;
      color: #5b8d26;
      font-size: 26px;
      font-weight: bold;
      padding: 0 10px 5px;
      border-bottom: 1px solid #5b8d26; }
    .template .title-wrap .title-line {
      display: inline-block;
      text-align: center;
      color: #b77c17;
      font-size: 21px;
      font-weight: bold;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px; }
      .template .title-wrap .title-line span {
        padding: 0 15px; }
      .template .title-wrap .title-line::before, .template .title-wrap .title-line::after {
        content: "";
        display: block;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        height: 1px;
        background: #b77c17; }
  .template .text {
    font-size: 14px;
    line-height: 1.8;
    text-align: left; }
    .template .text .caption {
      font-size: 21px;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 15px; }

.farmer-contents {
  background: #fefaf4;
  padding: 60px 20px 1px;
  margin: 0 -10px -10px; }
  .farmer-contents .title-wrap {
    margin-bottom: 20px;
    line-height: 1;
    text-align: center; }
    .farmer-contents .title-wrap .farmer-title {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      display: inline-block;
      position: relative;
      color: #b77c17; }
      .farmer-contents .title-wrap .farmer-title::before, .farmer-contents .title-wrap .farmer-title::after {
        content: "";
        display: inline-block;
        width: 2px;
        height: 60px;
        vertical-align: middle;
        background: #b77c17;
        position: absolute;
        bottom: 0; }
      .farmer-contents .title-wrap .farmer-title::before {
        left: 0;
        margin-left: -30px;
        transform: rotate(-18deg); }
      .farmer-contents .title-wrap .farmer-title::after {
        right: 0;
        margin-right: -30px;
        transform: rotate(18deg); }
      .farmer-contents .title-wrap .farmer-title .sub {
        font-size: 20px;
        display: block;
        margin-bottom: 10px; }
  .farmer-contents .text {
    padding: 0; }

.farmer-info-wrap {
  margin-bottom: 60px;
  align-items: center; }
  .farmer-info-wrap p {
    font-size: 14px; }
  .farmer-info-wrap .farmer-outline {
    margin-top: 20px; }
  .farmer-info-wrap .address {
    font-size: 13px;
    margin-bottom: 5px; }
  .farmer-info-wrap .name {
    font-size: 21px;
    font-weight: bold; }
  .farmer-info-wrap .icon-wrap {
    margin: 10px 0 20px; }
    .farmer-info-wrap .icon-wrap .icon {
      display: inline-block;
      border: 1px solid #ddd;
      background: #fff;
      font-size: 11px;
      font-weight: bold;
      margin: 1px;
      padding: 4px 8px; }
  .farmer-info-wrap .farmer-txt {
    line-height: 1.8;
    margin: 15px 0; }
  .farmer-info-wrap .farmer-msg {
    border: 1px solid #eee;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    position: relative; }
    .farmer-info-wrap .farmer-msg::before {
      content: "お客様へののメッセージ";
      display: block;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px; }

.award-history {
  padding: 15px;
  border: 2px solid #eee;
  background: #fff;
  margin: -30px 0 60px; }
  .award-history::after {
    content: "";
    display: block;
    clear: both; }
  .award-history img {
    margin-bottom: 15px; }
  .award-history dl {
    text-align: left; }
    .award-history dl dt {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px; }
    .award-history dl dd {
      font-size: 12px;
      margin: 8px 0; }

table.farmer-spac {
  width: 100%;
  font-size: 95%;
  margin: 30px 0; }
  table.farmer-spac * {
    display: block; }
  table.farmer-spac caption {
    margin: 0;
    text-align: left;
    letter-spacing: 1px;
    color: #fff;
    background: #2e3739;
    padding: 6px 10px; }
  table.farmer-spac td,
  table.farmer-spac th {
    padding: 6px 8px;
    border: 1px solid #d8d8d8;
    margin-top: -1px; }
  table.farmer-spac td:first-child[colspan="2"] {
    background: #2e3739; }
  table.farmer-spac td input {
    margin: 3px 0; }
  table.farmer-spac th {
    background: #f7f7f7;
    text-align: left; }
  table.farmer-spac td {
    background: #fff; }

/* 重要なお知らせ */
dl.important-news {
  margin: 30px 15px;
  border: 2px solid #e61f1f;
  padding: 20px 15px 20px 20px;
  text-align: left;
  color: #e61f1f; }
  dl.important-news dt {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px; }
  dl.important-news dd {
    font-size: 13px;
    line-height: 1.8; }

/* 定期購入カテゴリ（一覧）
----------------------------------------------- */
#repeat-sale-category {
  text-align: left;
  font-size: 16px;
  line-height: 1.8; }
  #repeat-sale-category .main-image {
    margin: 0 0 30px; }
  #repeat-sale-category .caption-wrap {
    padding: 0 15px; }
    #repeat-sale-category .caption-wrap .img {
      margin: 30px 0; }
  #repeat-sale-category .caption-title {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px; }
  #repeat-sale-category .point-wrap {
    margin: 50px 0 0;
    padding: 0 15px; }
    #repeat-sale-category .point-wrap .r_subGroup_title {
      border: none;
      padding: 0;
      background: none;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.3;
      text-align: center; }
  #repeat-sale-category .point-inner-wrap {
    margin-bottom: 50px; }
    #repeat-sale-category .point-inner-wrap .title-wrap {
      border: 1px solid #333;
      border-left: none;
      border-right: none;
      padding: 18px 0;
      margin: 0 0 30px;
      font-weight: bold;
      text-align: left; }
      #repeat-sale-category .point-inner-wrap .title-wrap .label {
        display: inline-block;
        color: #fff;
        font-size: 13px;
        background: #e22828;
        padding: 3px 12px;
        margin-right: 10px;
        border-radius: 3px; }
      #repeat-sale-category .point-inner-wrap .title-wrap .num {
        font-size: 16px; }
      #repeat-sale-category .point-inner-wrap .title-wrap .title {
        font-size: 21px;
        display: block;
        margin: 5px 0 0;
        line-height: 1.4;
        text-align: left; }
    #repeat-sale-category .point-inner-wrap .txt-wrap p {
      margin: 0 0 20px; }
    #repeat-sale-category .point-inner-wrap .txt-wrap dl {
      margin: 0 0 20px;
      padding: 0; }
    #repeat-sale-category .point-inner-wrap .txt-wrap dt {
      font-weight: bold;
      margin: 0;
      padding: 0; }
      #repeat-sale-category .point-inner-wrap .txt-wrap dt span {
        font-size: 14px; }
    #repeat-sale-category .point-inner-wrap .txt-wrap dd {
      margin: 0 0 15px;
      padding: 12px 0 15px;
      border-bottom: 1px solid #ddd; }
      #repeat-sale-category .point-inner-wrap .txt-wrap dd.last {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none; }
    #repeat-sale-category .point-inner-wrap ul.caution {
      padding-left: 15px; }
      #repeat-sale-category .point-inner-wrap ul.caution li {
        text-align: left;
        list-style: disc;
        margin: 10px 0;
        font-size: 13px;
        line-height: 1.8; }
      #repeat-sale-category .point-inner-wrap ul.caution .btn {
        display: inline-block;
        background: #2e3739;
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        line-height: 1;
        padding: 10px 20px;
        border-radius: 100px;
        margin-top: 10px;
        margin-right: 5px;
        width: auto; }
      #repeat-sale-category .point-inner-wrap ul.caution .btn-line {
        background-color: #21CE26; }
  #repeat-sale-category .guide-wrap {
    border-radius: 10px;
    background: #f7f7f7;
    padding: 40px 20px;
    text-align: center;
    margin-bottom: 50px; }
    #repeat-sale-category .guide-wrap .guide-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0 0 20px;
      text-align: center; }
    #repeat-sale-category .guide-wrap .btn-wrap {
      text-align: center; }
      #repeat-sale-category .guide-wrap .btn-wrap .btn {
        display: inline-block;
        padding: 12px 25px;
        border-radius: 100px;
        background: #5b8d26;
        color: #fff;
        line-height: 1.4;
        font-size: 16px;
        text-decoration: none;
        width: auto; }
        #repeat-sale-category .guide-wrap .btn-wrap .btn:hover {
          opacity: 0.7; }
    #repeat-sale-category .guide-wrap .tel-wrap {
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px solid #ccc; }
      #repeat-sale-category .guide-wrap .tel-wrap .guide-title {
        font-weight: bold;
        margin-bottom: 25px;
        color: #E34545; }
      #repeat-sale-category .guide-wrap .tel-wrap .contact-btn {
        margin-top: 15px; }
        #repeat-sale-category .guide-wrap .tel-wrap .contact-btn .btn {
          display: inline-block;
          background: #2e3739;
          color: #fff;
          text-decoration: none;
          font-size: 13px;
          line-height: 1;
          padding: 10px 20px;
          border-radius: 100px;
          margin-top: 10px;
          margin-right: 5px;
          width: auto; }
        #repeat-sale-category .guide-wrap .tel-wrap .contact-btn .btn-line {
          background-color: #21CE26; }

/* ご利用ガイド（定期購入を追加）
----------------------------------------------- */
#page-shopping-guide #repeat-sale-info strong {
  font-size: 100%;
  display: inline; }
  #page-shopping-guide #repeat-sale-info strong::after {
    content: none; }

#page-shopping-guide #repeat-sale-info .flex-wrap {
  display: block;
  padding: 15px; }

#page-shopping-guide img {
  max-width: 100%; }

#page-shopping-guide .point-title {
  font-size: 21px;
  font-weight: bold;
  color: #e22828;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 0;
  border: none; }

#page-shopping-guide ul li {
  margin: 8px 0; }

#page-shopping-guide ul .main {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 0; }

#page-shopping-guide ul .sub {
  margin: 0 0 20px; }

#page-shopping-guide a.btn {
  text-align: center;
  line-height: 1.2;
  display: inline-block;
  color: #fff;
  background: #5b8d26;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 100px;
  transition: all 0.2s ease;
  margin-top: 15px;
  width: 100%; }
  #page-shopping-guide a.btn:hover {
    background-color: #e22828;
    transition: all 0.2s ease; }

#page-shopping-guide .step-wrap {
  padding: 0;
  margin-bottom: 30px; }
  #page-shopping-guide .step-wrap dl {
    padding: 0; }
  #page-shopping-guide .step-wrap .step-label {
    background: #d8af1c;
    color: #fff;
    line-height: 38px;
    height: 38px;
    padding: 0 5px 0 12px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-right: 22px;
    display: inline-block; }
    #page-shopping-guide .step-wrap .step-label::after {
      position: absolute;
      top: 0;
      right: -15px;
      content: "";
      border-style: solid;
      border-width: 19px 0 19px 15px;
      border-color: transparent transparent transparent #d8af1c; }
    #page-shopping-guide .step-wrap .step-label + .txt {
      display: block;
      margin-top: 15px; }
  #page-shopping-guide .step-wrap p {
    margin: 20px 0; }
  #page-shopping-guide .step-wrap dt {
    font-size: 18px; }
  #page-shopping-guide .step-wrap .note {
    padding-left: 25px;
    position: relative;
    color: #666; }
    #page-shopping-guide .step-wrap .note::before {
      content: "＊";
      position: absolute;
      top: 0px;
      left: 2px; }

#page-shopping-guide .colorbox-img {
  padding: 0 15px; }

#page-shopping-guide .colorbox-img p {
  margin-top: 5px; }

#page-shopping-guide .access-wrap {
  margin-top: 30px; }
  #page-shopping-guide .access-wrap .caption {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold; }
  #page-shopping-guide .access-wrap .img-wrap {
    max-width: 300px; }
    #page-shopping-guide .access-wrap .img-wrap p {
      margin-bottom: 0; }

/* 重要なお知らせ枠
----------------------------------------------- */
.important-news {
  display: block;
  border: 3px solid #c7000b;
  color: #c7000b !important;
  text-decoration: none !important;
  margin: 15px 15px 10px; }
  .important-news .important-news-title {
    display: block;
    margin: 0;
    padding: 20px 20px 10px;
    color: #c7000b !important;
    position: relative;
    text-decoration: none;
    text-align: left; }
  .important-news .collapse-toggle:hover, .important-news .collapse-toggle:active {
    color: #c7000b !important;
    text-decoration: none !important; }
  .important-news .collapse-toggle::after {
    position: absolute;
    display: inline-block;
    content: "\f107";
    margin: auto;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    right: 15px;
    top: 20px; }
  .important-news .collapse-toggle:hover {
    cursor: pointer; }
  .important-news .collapse-toggle.open::after {
    content: "\f106"; }
  .important-news .collapse-inner {
    display: none;
    text-align: left;
    font-size: 13px; }
  .important-news dl {
    margin: 0;
    padding: 20px; }
  .important-news .dt,
  .important-news .dd {
    display: inline-block;
    margin: 0 0 10px;
    vertical-align: middle; }
  .important-news .dt {
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 20px; }
  .important-news .dd {
    font-size: 13px; }
  .important-news .important-news-inner .inner-wrap {
    padding: 20px 20px 10px;
    background: rgba(199, 0, 11, 0.05); }
  .important-news .important-news-inner p {
    margin-bottom: 10px;
    line-height: 1.8; }
  .important-news .important-news-inner a {
    font-weight: bold;
    display: inline-block; }
  .important-news.takarakuji {
    display: block;
    margin: -5px 15px 10px; }
    .important-news.takarakuji .important-news-title:hover, .important-news.takarakuji .important-news-title:active {
      color: #c7000b !important;
      text-decoration: none !important; }
    .important-news.takarakuji .important-news-title::after {
      content: "";
      position: absolute;
      display: inline-block;
      content: "\f105";
      margin: auto;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      right: 15px;
      top: 20px; }

.fc-red {
  color: #ff0000; }

.fw-b {
  font-weight: bold; }

/* カテゴリ おすすめコンテンツ
----------------------------------------------- */
.for-recommend-wrap {
  margin: 30px 0;
  padding: 0; }
  .for-recommend-wrap dt {
    padding: 10px 15px;
    margin: 0;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    line-height: 1.3;
    background: #5D9027;
    color: #FFF;
    text-shadow: 3px 3px 1px #3c5d18; }
  .for-recommend-wrap dd {
    margin: 0;
    padding: 20px;
    background: #FFFEEC;
    text-align: left; }
  .for-recommend-wrap ul {
    margin: 0;
    padding: 0; }
  .for-recommend-wrap li {
    margin: 0 0 15px;
    padding: 0;
    padding-left: 35px;
    list-style: none;
    position: relative;
    font-size: 15px;
    font-weight: 500; }
    .for-recommend-wrap li:nth-last-child(-n + 1) {
      margin-bottom: 0; }
    .for-recommend-wrap li::before {
      content: '';
      display: block;
      background: url(../images/category/icon-check.svg) no-repeat 50%;
      background-size: 100%;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 3px; }

/* ミルキークイーン カテゴリ
----------------------------------------------- */
.bold {
  font-weight: bold; }

.red {
  color: #CC0000; }

#milky-queen-category {
  padding: 0 15px; }
  #milky-queen-category img {
    max-width: 100%; }
  #milky-queen-category .main-image {
    border: none;
    background: none;
    padding: 0;
    margin: 0 -15px 30px; }
  #milky-queen-category .caption-wrap {
    text-align: left; }
  #milky-queen-category p {
    font-size: 16px;
    line-height: 1.8; }
  #milky-queen-category .section {
    padding: 0;
    margin: 0 0 80px; }
  #milky-queen-category .bd-title {
    border: none;
    border-radius: 0;
    background: none;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    padding: 12px 0;
    margin-bottom: 30px;
    line-height: 1.3; }
    #milky-queen-category .bd-title .num-label {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 20px; }
  #milky-queen-category .balloon {
    text-align: center;
    margin: 0 auto 30px;
    padding: 0 30px;
    background: none;
    border: none;
    color: #b77c17;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    display: inline-block; }
    #milky-queen-category .balloon .small {
      display: block;
      font-size: 21px; }
    #milky-queen-category .balloon:before, #milky-queen-category .balloon:after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 8px;
      width: 0;
      height: 60%;
      border-right: 1px solid #b77c17; }
    #milky-queen-category .balloon:before {
      transform: rotate(-15deg);
      margin-right: 35px;
      left: 0; }
    #milky-queen-category .balloon:after {
      transform: rotate(15deg);
      margin-left: 35px;
      right: 0; }
    #milky-queen-category .balloon.mini {
      font-size: 14px;
      line-height: 1.4;
      padding: 0 20px; }
      #milky-queen-category .balloon.mini:before, #milky-queen-category .balloon.mini:after {
        bottom: 0; }
  #milky-queen-category .caption-wrap {
    margin-bottom: 30px; }
  #milky-queen-category .anchor-toc {
    display: inline-block;
    text-align: left;
    margin-bottom: 30px; }
    #milky-queen-category .anchor-toc li {
      margin: 10px 0; }
    #milky-queen-category .anchor-toc a {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      color: #222;
      font-size: 14px;
      font-weight: bold; }
    #milky-queen-category .anchor-toc .num-label {
      border-radius: 100px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: block;
      flex-shrink: 0;
      flex-grow: 0; }
    #milky-queen-category .anchor-toc .num-label span {
      font-size: 14px;
      font-weight: 500; }
  #milky-queen-category .num-label {
    padding: 6px 12px;
    margin-right: 8px;
    border-radius: 5px;
    background: #D8AF1C;
    color: #FFF;
    font-size: 11px;
    line-height: 1.2; }
    #milky-queen-category .num-label span {
      font-size: 16px; }
  #milky-queen-category .section.reason {
    border: none; }
  #milky-queen-category .reason-wrap {
    margin-bottom: 50px; }
    #milky-queen-category .reason-wrap .bd-title {
      font-size: 18px;
      text-align: center; }
      #milky-queen-category .reason-wrap .bd-title .num-label {
        display: inline-block;
        margin: 0 auto 10px; }
  #milky-queen-category .mini-knowledge {
    margin: 20px auto; }
    #milky-queen-category .mini-knowledge img {
      display: block;
      width: 112px;
      margin: 0 auto 20px; }
    #milky-queen-category .mini-knowledge p {
      font-size: 14px;
      text-align: left; }
  #milky-queen-category .reason-answer {
    background: #FFFEEC;
    padding: 20px; }
    #milky-queen-category .reason-answer p {
      font-size: 14px;
      text-align: left; }
  #milky-queen-category .img-wrap {
    margin-top: 10px; }
    #milky-queen-category .img-wrap img {
      width: 100%; }
  #milky-queen-category .voice-title {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center; }
    #milky-queen-category .voice-title .txt {
      order: 2;
      text-align: left;
      padding-left: 20px; }
      #milky-queen-category .voice-title .txt .small {
        display: block;
        font-size: 15px;
        font-weight: 500; }
      #milky-queen-category .voice-title .txt .big {
        font-size: 32px;
        font-weight: bold; }
    #milky-queen-category .voice-title img {
      order: 1;
      width: 104px; }
    #milky-queen-category .voice-title + p {
      background: #F5F5F5;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
      font-size: 12px; }
  #milky-queen-category .voice-img {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -5px;
    padding: 20px 0 0; }
    #milky-queen-category .voice-img li {
      flex-basis: 50%;
      padding: 10px 5px;
      box-sizing: border-box; }
  #milky-queen-category .modal-voice .txt {
    margin: 30px 0 0;
    padding: 20px;
    border-radius: 10px;
    background: #F5F5F5; }
    #milky-queen-category .modal-voice .txt p {
      font-size: 14px;
      text-align: left; }
  #milky-queen-category .modal-voice .title {
    font-weight: bold;
    border-bottom: 1px solid #000;
    margin: 0 0 10px;
    padding-bottom: 5px;
    font-size: 16px; }
  #milky-queen-category .attention-list {
    margin: 0;
    padding: 0; }
    #milky-queen-category .attention-list li {
      margin: 10px 0;
      border-radius: 10px; }
    #milky-queen-category .attention-list li.txt {
      padding: 20px;
      background: #F5F5F5;
      text-align: left; }
      #milky-queen-category .attention-list li.txt .title {
        font-size: 21px;
        font-weight: 500;
        text-align: left;
        color: #CC0000;
        margin: 0 0 15px; }
      #milky-queen-category .attention-list li.txt p {
        font-size: 14px; }
  #milky-queen-category .sub-category-list li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px; }
    #milky-queen-category .sub-category-list li img {
      display: block;
      width: 157px;
      flex-basis: 157px;
      flex-grow: 1;
      flex-shrink: 1; }
    #milky-queen-category .sub-category-list li .txt {
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      text-align: left;
      padding-left: 12px; }
  #milky-queen-category .sub-category-list .title {
    color: #222;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.3;
    margin: 5px 0 10px;
    text-align: left; }
  #milky-queen-category .sub-category-list .small {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5;
    color: #CC0000; }
  #milky-queen-category .sub-category-list .btn {
    display: inline-block;
    padding: 5px 12px;
    color: #5B8D26;
    border: 1px solid #5B8D26;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    font-weight: bold; }
  #milky-queen-category .how-to-cook {
    text-align: left; }
    #milky-queen-category .how-to-cook .bold {
      margin-top: 20px; }
    #milky-queen-category .how-to-cook table {
      width: 100%;
      margin: 15px 0; }
      #milky-queen-category .how-to-cook table th, #milky-queen-category .how-to-cook table td {
        text-align: center;
        border: 1px solid #ccc;
        font-size: 16px;
        padding: 8px;
        vertical-align: middle; }
      #milky-queen-category .how-to-cook table th {
        background: #FFFEEC;
        font-weight: bold; }
    #milky-queen-category .how-to-cook .note {
      font-size: 13px; }
  #milky-queen-category .knowledge .bd-title {
    background: url(../images/category/bg-knowledge-title@2x.png) 50% 70% no-repeat;
    background-size: 100%;
    padding: 20px 0;
    font-size: 24px; }
    #milky-queen-category .knowledge .bd-title .small {
      display: block;
      margin-bottom: 5px;
      font-size: 15px; }
  #milky-queen-category .knowledge-scroll-wrap {
    background: #F5F5F5;
    padding: 20px;
    border-radius: 10px;
    text-align: left; }
    #milky-queen-category .knowledge-scroll-wrap .inner {
      max-height: 300px;
      overflow-y: scroll;
      padding-right: 10px;
      /* スクロールの幅の設定 */
      /* スクロールの背景の設定 */
      /* スクロールのつまみ部分の設定 */ }
      #milky-queen-category .knowledge-scroll-wrap .inner::-webkit-scrollbar {
        width: 6px;
        height: 6px; }
      #milky-queen-category .knowledge-scroll-wrap .inner::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ddd; }
      #milky-queen-category .knowledge-scroll-wrap .inner::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #2D3739; }
    #milky-queen-category .knowledge-scroll-wrap h3 {
      font-size: 16px;
      border-bottom: 1px solid #999;
      padding-bottom: 8px;
      margin: 35px auto 18px; }
      #milky-queen-category .knowledge-scroll-wrap h3:first-child {
        margin-top: 0; }
    #milky-queen-category .knowledge-scroll-wrap p {
      margin: 0 0 13px;
      font-size: 13px; }

.fixed-anchor {
  position: fixed;
  bottom: 17px;
  left: -5px;
  width: 147px;
  z-index: 20; }

.modal-open:hover {
  cursor: pointer;
  opacity: 0.8; }

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto; }
  .modal .modal-contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .modal .modal-inner {
    background: #fff;
    padding: 30px;
    position: relative;
    z-index: 101;
    box-sizing: border-box; }
    .modal .modal-inner .modal-off {
      position: absolute;
      top: 0;
      right: 0;
      background: #eee;
      border: none;
      font-size: 10px;
      padding: 0.5rem;
      border-radius: 0;
      line-height: 1;
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      text-align: center; }
      .modal .modal-inner .modal-off:hover {
        background: #ddd;
        cursor: pointer; }
  .modal .modalBK {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100; }

/*****************************************************
商品一覧ページ（独自ページ）を追加
*****************************************************/
#item-list-wrapper .inner-wrap {
  padding: 15px; }

#item-list-wrapper .currentName {
  margin: 30px 5px 0; }

#item-list-wrapper .searchArea {
  padding: 5px;
  padding-right: 0;
  letter-spacing: -1rem; }
  #item-list-wrapper .searchArea input[name="brand_search_form"] {
    width: 85%;
    letter-spacing: normal; }
  #item-list-wrapper .searchArea input[name="brand_search_button"] {
    width: 15%;
    height: 36px;
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images_sp/icon_serch.svg) no-repeat 50% #333;
    background-size: 18px 18px;
    padding: 0; }

#item-list-wrapper .search-hint {
  margin: 6px -3px 0;
  text-align: left; }
  #item-list-wrapper .search-hint a {
    display: block;
    padding: 5px;
    border: 1px solid #333;
    letter-spacing: normal; }
  #item-list-wrapper .search-hint i.fa {
    padding-right: 2px; }

#item-list-wrapper .sideCategory1,
#item-list-wrapper .sideCategory2,
#item-list-wrapper .sideCategory3 {
  border-bottom: none; }
